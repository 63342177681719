import React, { useMemo } from 'react'
import { Nullish } from 'types'
import { isSimpleArrayEquals } from 'utils'
import { UICheckboxItem } from '../UICheckboxItem/UICheckboxItem'
import { UIDropdown } from '../UIDropdown/UIDropdown'

export interface CheckboxInfo<T> {
  id: T
  label: string
  icon?: string
}

export function UIFilterDropdown<T>({
  buttonName,
  checkboxes,
  onClickCheckbox,
  value,
}: {
  buttonName: string
  checkboxes: CheckboxInfo<T>[]
  onClickCheckbox: (id: T[] | null) => void
  value: T[] | null // null means all
}) {
  const allOptions = useMemo(() => {
    return checkboxes.map((item) => item.id)
  }, [checkboxes])

  const selectedCheckboxes = useMemo(() => {
    if (value === null) {
      return allOptions
    }

    if (isSimpleArrayEquals(allOptions, [null, ...value])) {
      return allOptions
    }

    return value
  }, [allOptions, value])

  const buttonTitle = useMemo(() => {
    if (value === null) return buttonName

    return `${buttonName} (${selectedCheckboxes.length})`
  }, [buttonName, selectedCheckboxes.length, value])

  const handleClickCheckbox = (id: Nullish<T>) => {
    if (id === null) {
      let result: Nullish<T[]> = null
      if (isSimpleArrayEquals(allOptions, selectedCheckboxes)) {
        result = []
      }

      onClickCheckbox(result)
      return
    }

    if (value === null) {
      onClickCheckbox([id])
      return
    }

    if (isSimpleArrayEquals(allOptions, [null, id, ...value])) {
      onClickCheckbox(null)
      return
    }

    let newTagIds
    if (value.includes(id)) {
      newTagIds = value.filter((filterId) => id !== filterId)
    } else {
      newTagIds = value.concat(id)
    }
    onClickCheckbox(newTagIds)
    return
  }

  return (
    <UIDropdown buttonName={buttonTitle} buttonColor={value === null ? 'primary' : 'secondary'}>
      {checkboxes.map((item) => {
        return (
          <UICheckboxItem<T>
            key={String(item.id)}
            id={item.id}
            label={item.label}
            icon={item.icon}
            checked={selectedCheckboxes.includes(item.id)}
            onClick={handleClickCheckbox}
          />
        )
      })}
    </UIDropdown>
  )
}
