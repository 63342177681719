import { Box, Stack, Tab, Tabs } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import {
  getActiveEngagementPerCareArticleReport,
  getActiveEngagementPerLearnArticleReport,
  getActiveEngagementPerLessonReport,
  getActiveEngagementQuestionnaireReport,
} from 'api/reports'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import {
  createCohortsNamesColumn,
  createCohortsNamesCsvColumn,
  useCohortsFilter,
} from 'features/Cohorts'
import { CSVField, useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import { useTabsControl } from 'hooks/useTabsControl'
import React, { useCallback, useMemo } from 'react'
import { BaseTableRequest, Engagement, FilterModel, IMetricType } from 'types'
import { useGetDataSource } from 'utils'
import { createEngagementRenderer } from '../../helpers/createEngagementRenderer'
import { formatEngagement } from '../../helpers/formatEngagement'
import { prepareDateRangeParams } from '../../helpers/prepareDateRangeParams'
import { useDateRange } from '../../hooks/useDateRange'
import { DateRangeToolbar } from '../DateRangeToolbar/DateRangeToolbar'

const lessonColumnDefs: ColDef[] = [
  { headerName: 'Lesson Name', field: 'lessonName', filter: 'agTextColumnFilter' },
  { headerName: 'Course Name', field: 'courseName', filter: 'agTextColumnFilter' },
  createCohortsNamesColumn('cohortNames'),
  { headerName: 'Number of Users', field: 'numberOfUsers' },
  {
    headerName: 'Audio Time',
    field: 'audioTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('audioTime'),
  },
  {
    headerName: 'Video Time',
    field: 'videoTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('videoTime'),
  },
  {
    headerName: 'Lesson Time',
    field: 'lessonTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('lessonTime'),
  },
  {
    headerName: 'Quiz Time',
    field: 'quizTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('quizTime'),
  },
  {
    headerName: 'Total Time',
    field: 'totalTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('totalTime'),
  },
]

const learnArticleColumnDefs: ColDef[] = [
  { headerName: 'Learn Article Name', field: 'learnArticleName', filter: 'agTextColumnFilter' },
  { headerName: 'Topic Name', field: 'learnTopicName', filter: 'agTextColumnFilter' },
  createCohortsNamesColumn('cohortNames'),
  { headerName: 'Number of Users', field: 'numberOfUsers', maxWidth: 180 },
  {
    headerName: 'Audio Time',
    field: 'audioTime',
    maxWidth: 140,
    cellRenderer: createEngagementRenderer('audioTime'),
  },
  {
    headerName: 'Video Time',
    field: 'videoTime',
    maxWidth: 140,
    cellRenderer: createEngagementRenderer('videoTime'),
  },
  {
    headerName: 'Learn Article Time',
    field: 'learnArticleTime',
    maxWidth: 180,
    cellRenderer: createEngagementRenderer('learnArticleTime'),
  },
  {
    headerName: 'Query Time',
    field: 'queryTime',
    maxWidth: 140,
    cellRenderer: createEngagementRenderer('queryTime'),
  },
  {
    headerName: 'Total Time',
    field: 'totalTime',
    maxWidth: 140,
    cellRenderer: createEngagementRenderer('totalTime'),
  },
]

const careArticleColumnDefs: ColDef[] = [
  { headerName: 'Timeline Post Name', field: 'careArticleName', filter: 'agTextColumnFilter' },
  createCohortsNamesColumn('cohortNames'),
  { headerName: 'Number of Users', field: 'numberOfUsers' },
  {
    headerName: 'Audio Time',
    field: 'audioTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('audioTime'),
  },
  {
    headerName: 'Video Time',
    field: 'videoTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('videoTime'),
  },
  {
    headerName: 'Timeline Post Time',
    field: 'careArticleTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('careArticleTime'),
  },
]

const questionnaireColumnDefs: ColDef[] = [
  { headerName: 'Exam Name', field: 'questionnaireName', filter: 'agTextColumnFilter' },
  createCohortsNamesColumn('cohortNames'),
  { headerName: 'Number of Users', field: 'numberOfUsers' },
  {
    headerName: 'Audio Time',
    field: 'audioTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('audioTime'),
  },
  {
    headerName: 'Exam Time',
    field: 'questionnaireTime',
    minWidth: 120,
    cellRenderer: createEngagementRenderer('questionnaireTime'),
  },
]

const MetricOptions = {
  [IMetricType.lesson]: {
    title: 'Lessons',
    apiMethod: getActiveEngagementPerLessonReport,
    columns: lessonColumnDefs,
  },
  [IMetricType.learnArticle]: {
    title: 'Learn Articles',
    apiMethod: getActiveEngagementPerLearnArticleReport,
    columns: learnArticleColumnDefs,
  },
  [IMetricType.careArticle]: {
    title: 'Timeline Posts',
    apiMethod: getActiveEngagementPerCareArticleReport,
    columns: careArticleColumnDefs,
  },
  [IMetricType.questionnaire]: {
    title: 'Exams',
    apiMethod: getActiveEngagementQuestionnaireReport,
    columns: questionnaireColumnDefs,
  },
}

const MetricEntities = [
  IMetricType.lesson,
  IMetricType.learnArticle,
  IMetricType.careArticle,
  IMetricType.questionnaire,
]

export function ReportsEngagement() {
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<IMetricType>(IMetricType.lesson)

  const { cohortsFilterValue, CohortsFilter } = useCohortsFilter()

  const {
    rangeType,
    year,
    month,
    selectedDate,
    dateInterval,
    onChangeDateInterval,
    onChangeRange,
    onChangeYear,
    onChangeMonth,
    setSelectedDate,
  } = useDateRange()

  const getApiWrapper = useCallback(
    (req: BaseTableRequest) => {
      const dateParams = prepareDateRangeParams(rangeType, year, month, selectedDate, dateInterval)

      const filter: FilterModel = {
        ...(req.filter || {}),
      }

      if (cohortsFilterValue) {
        filter.cohortId = cohortsFilterValue
      }

      return MetricOptions[selectedTab].apiMethod({
        ...req,
        filter,
        params: dateParams,
      })
    },
    [cohortsFilterValue, dateInterval, month, rangeType, selectedDate, selectedTab, year],
  )

  const getDataSource = useGetDataSource(getApiWrapper)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)

  useRefreshTable({ params: { selectedTab, rangeType, year, month, selectedDate }, gridApi })

  const csvFields: CSVField[] = useMemo(() => {
    const columns = MetricOptions[selectedTab].columns
    const fields: CSVField[] = []

    columns.forEach((columnDef) => {
      if (!columnDef.field) {
        return
      }
      let value: string | ((record: never) => string) = columnDef.field
      if (columnDef.field.toLowerCase().includes('time')) {
        value = (data: never) => {
          if (!columnDef.field) {
            return ''
          }
          return formatEngagement(data[columnDef.field] as Engagement)
        }
      }
      if (columnDef.field.includes('cohortNames')) {
        value = createCohortsNamesCsvColumn(columnDef.field).value
      }
      fields.push({
        label: columnDef.headerName,
        value,
      } as CSVField)
    })

    return fields
  }, [selectedTab])

  const { csvLoading, onExportCSV } = useExportCSV(
    getApiWrapper,
    gridApi,
    columnApi,
    csvFields,
    `${MetricOptions[selectedTab].title} Engagement Report.csv`,
  )

  return (
    <>
      <TableToolbar
        left={
          <Tabs value={selectedTab} onChange={handleChangeSelectedTab} indicatorColor="primary">
            {MetricEntities.map((entityId) => {
              return (
                <Tab
                  key={entityId}
                  value={entityId}
                  label={getTitle(MetricOptions[entityId].title)}
                />
              )
            })}
          </Tabs>
        }
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />
      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        <DateRangeToolbar
          rangeType={rangeType}
          year={year}
          month={month}
          dateInterval={dateInterval}
          onChangeDateInterval={onChangeDateInterval}
          selectedDate={selectedDate}
          onChangeRange={onChangeRange}
          onChangeYear={onChangeYear}
          onChangeMonth={onChangeMonth}
          setSelectedDate={setSelectedDate}
        />

        <Box minWidth="200px">{CohortsFilter}</Box>
      </Stack>

      <Box mb={2} height="100%">
        <InfinityAgGrid
          pagination
          columnDefs={MetricOptions[selectedTab].columns}
          onGridReady={onGridReady}
        />
      </Box>
    </>
  )
}
