import { Stack } from '@mui/material'
import { CourseCollapseItem } from 'app/imports/App.components'
import { UILearnArticleImg } from 'features/UI'
import { ReactNode } from 'react'
import { TimelineLine } from '../TimelineLine/TimelineLine'

export function TimelineCourseItem({
  id,
  name,
  bannerUrl,
  status,
  children,
}: {
  id: number
  name: string
  bannerUrl?: string | null
  status: 'new' | 'retake' | 'done'
  children: ReactNode
}) {
  return (
    <Stack direction="row" className="gap-16">
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        <UILearnArticleImg isOnTimeline />
        <TimelineLine />
      </div>

      <CourseCollapseItem id={id} name={name} bannerUrl={bannerUrl} status={status}>
        {children}
      </CourseCollapseItem>
    </Stack>
  )
}
