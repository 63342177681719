import { zodResolver } from '@hookform/resolvers/zod'
import { Button, CircularProgress, Grid } from '@mui/material'
import { getProfile, updateProfile as apiUpdateProfile } from 'api/profile'
import { useAuthContext } from 'components/context/AuthProvider'
import FormDatePicker from 'components/form-elements/FormDatePicker'
import FormInput from 'components/form-elements/FormInput'
import FormRadioGroup from 'components/form-elements/FormRadioGroup'
import NotificationSys from 'components/NotificationSystem'
import ModalFailPlaceholder from 'components/placeholders/ModalFailPlaceholder'
import Loader from 'components/placeholders/ModalLoadingPlaceholder'
import { EMAIL_IS_INVALID } from 'const'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useActions } from 'store/hooks'
import { BackendGender } from 'types'
import * as z from 'zod'

const genderOptions = {
  male: BackendGender.male,
  female: BackendGender.female,
  'Prefer to self describe': BackendGender.selfDescribe,
  'Prefer not to answer': BackendGender.notAnswer,
}

const required = z.string().min(1, 'Required')

const formSchema = z.object({
  email: required.email(EMAIL_IS_INVALID),
  firstName: required,
  lastName: required,
  street: required,
  zipCode: required,
  country: required,
  city: required,
  state: required,
  phoneNumber: required,
  dateOfBirth: z.date({
    required_error: 'Requried',
    invalid_type_error: 'Invalid date',
    coerce: true,
  }),
  gender: z
    .string()
    .nullable()
    .transform((value, ctx) => {
      if (value === null) {
        ctx.addIssue({ code: 'custom', message: 'Required' })
      }

      return value
    }),
})

const formSettings = {
  mode: 'onChange' as const,
  resolver: zodResolver(formSchema),
}

type FormType = z.TypeOf<typeof formSchema>

const ProfileBasicInfoForm = ({ onClose, userId }: { onClose: () => void; userId: number }) => {
  const [form, setForm] = useState<FormType>()
  const { user } = useAuthContext()

  const query = useQuery(['getProfile', userId], () => getProfile(userId), {
    onSuccess: (data) => {
      const form = {
        ...data,
        dateOfBirth: moment(data.dateOfBirth, 'MM/DD/YYYY').toDate(),
      }

      setForm(form)
    },
  })

  if (query.isLoading) {
    return <Loader show />
  }

  if (query.isError)
    return (
      <ModalFailPlaceholder
        show={true}
        py={7}
        error="Can't load profile data"
        handleClose={onClose}
      />
    )
  if (form) {
    return <Profile readonly={user?.id != userId} {...form} />
  }

  return null
}

const Profile = (props: FormType & { readonly: boolean }) => {
  const formMethods = useForm<FormType>({ ...formSettings, defaultValues: props })
  const { updateProfile } = useActions()

  const formattedGenderOptions = useMemo(() => {
    return Object.entries(genderOptions).map((genderOption) => ({
      label: genderOption[0],
      value: genderOption[1],
    }))
  }, [])

  const updater = useMutation(apiUpdateProfile, {
    onSuccess: () => {
      updateProfile({ firstName: props.firstName, lastName: props.lastName })
      NotificationSys.showSuccess(`Profile successfully updated`)
    },
  })

  const onSubmit = (data: FormType) => {
    updater.mutate({
      ...data,
      dateOfBirth: moment(data.dateOfBirth).format('MM/DD/YYYY'),
      gender: data.gender as BackendGender,
    })
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
        <FormInput
          disabled={props.readonly}
          label="Email Address"
          required
          fullWidth
          sx={{ mb: 1 }}
          name="email"
        />

        <Grid container columnSpacing={2}>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="First Name"
              required
              fullWidth
              name="firstName"
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="Last Name"
              required
              fullWidth
              name="lastName"
              sx={{ mb: 1 }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="Street Address"
              required
              fullWidth
              name="street"
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="Zip Code"
              required
              fullWidth
              name="zipCode"
              sx={{ mb: 1 }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="City"
              required
              fullWidth
              name="city"
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="State"
              required
              fullWidth
              name="state"
              sx={{ mb: 1 }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} sx={{ mb: 1 }}>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="Country"
              required
              fullWidth
              name="country"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={props.readonly}
              label="Phone Number"
              required
              fullWidth
              name="phoneNumber"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormDatePicker
              disabled={props.readonly}
              label="Date of Birth"
              name="dateOfBirth"
              required
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormRadioGroup
              disabled={props.readonly}
              label="Gender"
              required
              name="gender"
              options={formattedGenderOptions}
            />
          </Grid>
        </Grid>

        {!props.readonly && (
          <Button
            variant="contained"
            type="submit"
            sx={{ minWidth: 160, height: 36.5, mt: 3, mb: 1, color: '#FFF' }}
          >
            {updater.isLoading && <CircularProgress size="1.5rem" color="inherit" />}
            {!updater.isLoading && 'Save'}
          </Button>
        )}
      </form>
    </FormProvider>
  )
}

export default React.memo(ProfileBasicInfoForm)
