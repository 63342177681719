import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IMetricScope, IMetricType } from 'types/metric.types'
import { downloadMediaFile } from '../../api/mediaFiles'
import AudioPlayer from './AudioPlayer'

const AudioView = ({
  mediaFileId,
  mediaFileMimeType,
  metricType,
  metricEntityId,
  onSaveMetric,
}: {
  mediaFileId: number | null
  mediaFileMimeType?: string
  metricType?: IMetricType
  metricEntityId?: number
  onSaveMetric: (value: number, uuid: string, scope: IMetricScope) => void
}) => {
  const [src, setSrc] = useState<string | null>(null)

  useEffect(() => {
    const load = async () => {
      if (mediaFileId) {
        const url = await downloadMediaFile(mediaFileId)
        setSrc(url)
      }
    }
    load()
  }, [mediaFileId])

  if (mediaFileId !== null && mediaFileId !== undefined && src === null) {
    return (
      <Stack mt={3} mb={1} direction="row" spacing={2} alignItems="center" minHeight="36px">
        <Typography>Loading Audio...</Typography>

        <CircularProgress style={{ width: 24, height: 24 }} />
      </Stack>
    )
  }

  if (!src) {
    return null
  }

  return (
    <Box mt={3} mb={1}>
      <AudioPlayer
        src={src || ''}
        mediaFileMimeType={mediaFileMimeType}
        metricType={metricType}
        metricEntityId={metricEntityId}
        onSaveMetric={onSaveMetric}
      />
    </Box>
  )
}
export default React.memo(AudioView)
