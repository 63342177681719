import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { styled, useTheme } from '@mui/material/styles'
import { alpha, Button, InputBase, Stack, Typography, useMediaQuery } from '@mui/material'
import { User } from 'types'
import { useDrawersContext } from '../context/ClientsDrawersProvider'
import { familyAddNoteTooltip, searchTooltip, traineeAddNoteTooltip } from '../tooltips/tooltips'
import HelpTooltip from '../tooltips/HelpTooltip'
import { useAuthContext } from '../context/AuthProvider'
import { addTimeObjects, formatTotalTime, parseTotalEngagement } from './utils'
import { getFromLocalStorage, saveToLocalStorage, USER_DATA } from '../../utils'
import { InputBaseProps } from '@mui/material/InputBase/InputBase'
import NotificationSys from '../NotificationSystem'

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(1),
  width: 'auto',
}))

const FooterContent = () => {
  const theme = useTheme()
  const { isTrainee } = useAuthContext()
  const { handleOpenNoteEdit, handleOpenSearchDrawer } = useDrawersContext()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

  const [totalTimeText, setTotalTimeText] = useState<string | null>(null)

  const [search, setSearch] = useState('')

  const onClickAddNote = () => {
    handleOpenNoteEdit()
  }

  const onSearch = () => {
    if (search.trim().length < 3) {
      NotificationSys.showWarning('Please enter 3 symbols or more to be able to search.')
      return
    }
    handleOpenSearchDrawer(search)
  }

  useEffect(() => {
    const user = getFromLocalStorage(USER_DATA) as User | null
    console.log('--USER_DATA in footer effect', user)
    console.log('--totalEngagement from LS', user?.totalEngagement)
    const totalTime = parseTotalEngagement(user?.totalEngagement)
    console.log('--totalTime', totalTime)
    const sessionTime = { hours: 0, minutes: 0, seconds: 0 }

    const renewTime = (timeInterval: number | null) => {
      console.log('--totalTime interval', totalTime)
      console.log('--sessionTime', sessionTime)
      const collectedTime = totalTime ? addTimeObjects(totalTime, sessionTime) : null
      console.log('--collectedTime', collectedTime)
      const totalTimeText = formatTotalTime(collectedTime)
      console.log('--totalTimeText', totalTimeText)
      // renew data of totalEngagement
      const currentUserData = getFromLocalStorage(USER_DATA) as User | null
      if (currentUserData && collectedTime) {
        const { hours, minutes, seconds } = collectedTime
        saveToLocalStorage(USER_DATA, {
          ...currentUserData,
          totalEngagement: `${hours}:${minutes}:${seconds}`,
        })
        console.log('--updatedUser', getFromLocalStorage(USER_DATA))

        if (timeInterval === 1000) {
          sessionTime.seconds++
        }

        if (timeInterval === 60000) {
          sessionTime.minutes++
        }
      }
      setTotalTimeText(totalTimeText)
      return collectedTime
    }

    const collected = renewTime(null)

    let interval = 60000
    if (collected?.hours === 0 && collected?.minutes === 0) {
      interval = 1000
    }

    const timerId = setInterval(() => {
      renewTime(interval)
    }, interval)

    return () => {
      console.log('--clear total time interval')
      clearInterval(timerId)
    }
  }, [])

  const onKeyDown: InputBaseProps['onKeyDown'] = (e) => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
    >
      {!matchesXs && (
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack direction="row">
            <HelpTooltip text={searchTooltip} placement="top">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>

                <StyledInputBase
                  placeholder="Enter search terms…"
                  inputProps={{ 'aria-label': 'search' }}
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  onKeyDown={onKeyDown}
                />
              </Search>
            </HelpTooltip>
            <Button variant="contained" onClick={onSearch}>
              Search
            </Button>
            <HelpTooltip
              text={isTrainee ? traineeAddNoteTooltip : familyAddNoteTooltip}
              placement="top"
            >
              <Button variant="contained" color="secondary" onClick={onClickAddNote} sx={{ ml: 4 }}>
                Add note
              </Button>
            </HelpTooltip>
          </Stack>
        </Stack>
      )}

      {totalTimeText && (
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          <Typography variant="body2" color="textSecondary">
            Total Time Online:{'\u00A0'}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {totalTimeText}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default React.memo(FooterContent)
