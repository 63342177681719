import { IGetRowsParams } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController'
import { Option } from 'components/form-elements/FormSelect'
import { ROWS_PER_PAGE } from 'hooks/useGridControl'
import moment from 'moment'
import { useCallback } from 'react'
import {
  AgFilterModel,
  AgFilterModelDate,
  AgFilterModelText,
  AgNumberModelDate,
  BaseTableRequest,
  FilterModel,
  NameAndIdEntity,
  SortModel,
  TableResponse,
} from 'types'

interface AgParams {
  startRow?: number
  sortModel: SortModelItem[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterModel: any
}

const prepareAgFilter = (filter: AgFilterModelText | AgFilterModelDate | AgNumberModelDate) => {
  switch (filter.filterType) {
    case 'text':
      return {
        type: filter.filterType,
        op: filter.type,
        value: filter.filter,
      }
    case 'date':
      return {
        type: filter.filterType,
        op: filter.type,
        value: filter.type == 'equals' ? moment(filter.dateFrom).format('YYYY-MM-DD') : null,
        valueFrom:
          filter.type == 'inRange' && filter.dateFrom
            ? moment(filter.dateFrom).format('YYYY-MM-DD')
            : null,
        valueTo:
          filter.type == 'inRange' && filter.dateTo
            ? moment(filter.dateTo).format('YYYY-MM-DD')
            : null,
      }
    case 'number':
      return {
        type: filter.filterType,
        op: filter.type,
        value: filter.filter,
        valueFrom: filter.filter,
        valueTo: filter.filterTo,
      }
  }
}

const prepareFilters = (filters: AgFilterModel) => {
  const filter: FilterModel = {}
  if (filters) {
    for (const key in filters) {
      filter[key] = prepareAgFilter(filters[key])
    }
  }
  return filter
}

export const prepareTableParams = (params: AgParams): BaseTableRequest => {
  const { sortModel, startRow } = params
  const page = startRow !== undefined ? Math.trunc(startRow / ROWS_PER_PAGE) + 1 : undefined

  // Transform into backend model
  const filter = prepareFilters(params.filterModel as AgFilterModel)

  let sort: SortModel | null = null
  const agSort = sortModel.length ? sortModel[0] : null
  if (agSort) {
    sort = {
      field: agSort.colId,
      sort: agSort.sort,
    }
  }

  return {
    pagination:
      page !== undefined
        ? {
            page,
            pageSize: ROWS_PER_PAGE,
          }
        : undefined,
    filter,
    sort,
  }
}

export const prepareTableRequestFilterByName = (name: string) => {
  let filter = {}
  if (name !== '') {
    filter = {
      ['name']: {
        type: 'text',
        op: 'contains',
        value: name,
      },
    }
  }
  return {
    filter,
    pagination: {
      page: 1,
      pageSize: 25,
    },
    sort: null,
  }
}

export const prepareTableRequestFilterByNameAndLinks = (name: string) => {
  let filter: {} = {
    ['lessonId']: {
      type: 'number',
      op: 'blank',
    },
    ['careArticleId']: {
      type: 'number',
      op: 'blank',
    },
  }
  if (name !== '') {
    filter = {
      ...filter,
      ['name']: {
        type: 'text',
        op: 'contains',
        value: name,
      },
    }
  }
  return {
    filter,
    pagination: {
      page: 1,
      pageSize: 25,
    },
    sort: null,
  }
}

export const prepareTableRequestFilterByNameAndLLId = (name: string) => {
  let filter: {} = {
    ['learnArticleId']: {
      type: 'number',
      op: 'blank',
    },
  }
  if (name !== '') {
    filter = {
      ...filter,
      ['name']: {
        type: 'text',
        op: 'contains',
        value: name,
      },
    }
  }
  return {
    filter,
    pagination: {
      page: 1,
      pageSize: 25,
    },
    sort: null,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetDataSource = (requestCallback: (request: BaseTableRequest) => Promise<any>) => {
  return useCallback(
    (gridApi: GridApi) => {
      return {
        getRows: (params: IGetRowsParams) => {
          gridApi.hideOverlay()
          gridApi.showLoadingOverlay()

          requestCallback(prepareTableParams(params))
            .then((resp) => resp.data)
            .then((data) => {
              if ('rows' in data) {
                params.successCallback(data.rows, data.count)
                gridApi.hideOverlay()
                if (data.count === 0) {
                  gridApi.showNoRowsOverlay()
                }
              } else {
                // TODO remove it
                params.successCallback(data, data.length)
                gridApi.hideOverlay()
                if (data.length === 0) {
                  gridApi.showNoRowsOverlay()
                }
              }
            })
            .catch(() => {
              params.successCallback([], 0)
              gridApi.hideOverlay()
              gridApi.showNoRowsOverlay()
            })
        },
      }
    },
    [requestCallback],
  )
}

export const backendDataMapper = <T extends NameAndIdEntity>(it: T) => ({
  label: it.name,
  value: it.id || '',
})

export const selectOptionsFromTableResponse = <T extends NameAndIdEntity>(
  response: TableResponse<T>,
): Option[] => response.data.rows.map(backendDataMapper) || []
