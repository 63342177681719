import { useMemo } from 'react'
import replace from 'lodash/replace'

export const withIdParam = (route: string, id: string | number) => {
  return withParams(route, { id })
}

export const withParams = (route: string, params: Record<string, string | number>) => {
  const query = Object.entries(params)
    .map((p) => `${p[0]}=${p[1]}`)
    .join('&')
  return `${route}?${query}`
}

// URLSearchParams parse string "test+test1@mail.com" to "test test1@mail.com"
// so parse query params in more simple way
export function getQueryParams() {
  const hashes = location.search.slice(location.search.indexOf('?') + 1).split('&')
  const params: { [key: string]: string } = {}
  hashes.map((hash) => {
    const [key, value] = hash.split('=')
    params[key] = decodeURIComponent(value)
  })
  return params
}

export function convertSearchParamsToObject(params: URLSearchParams): {
  [key: string]: string | undefined
} {
  const obj: { [key: string]: string } = {}
  params.forEach((value, key) => {
    obj[key] = value
  })
  return obj
}

export const useSearchParamsObject = () => {
  return useMemo(() => {
    const params = new URLSearchParams(location.search)
    return convertSearchParamsToObject(params)
  }, [])
}

export const getRoute = (route: string, params: { [key: string]: string | number }) => {
  let result = route
  Object.keys(params).forEach((key) => {
    result = replace(result, `:${key}`, params[key].toString())
  })

  return result
}
