import { UIFilterDropdown } from 'features/UI'
import IconLesson from 'images/icons/1_man_with_board.png'
import IconTimelinePost from 'images/icons/icon_article.png'
import IconNotes from 'images/icons/icon_journal.png'
import IconQuestion from 'images/icons/icon_sm_question.png'
import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { selectTimelineItems } from 'store/slice/timeline.slice'
import {
  selectTimelineFilter,
  selectTimelineFiltersTypes,
  setTimelineFiltersTypes,
} from 'store/slice/timelineFilters.slice'
import { ETimelinePostType, Nullish, TimelineFilterStatusMap } from 'types'

const filterItems = [
  {
    id: null,
    label: 'All',
  },
  {
    id: ETimelinePostType.note,
    label: 'Notes',
    icon: IconNotes,
  },
  {
    id: ETimelinePostType.queries,
    label: 'Queries',
    icon: IconQuestion,
  },
  {
    id: ETimelinePostType.course,
    label: 'Courses',
    icon: IconLesson,
  },
  {
    id: ETimelinePostType.careArticle,
    label: 'Timeline Posts',
    icon: IconTimelinePost,
  },
]

export function TimelineFilterByType() {
  const dispatch = useAppDispatch()
  const timelineTypes = useSelector(selectTimelineFiltersTypes)
  const status = useSelector(selectTimelineFilter)
  const timeline = useSelector(selectTimelineItems)

  const checkboxes = useMemo(() => {
    return filterItems.map((filterItem) => {
      let amount = 0
      if (timeline && filterItem.id) {
        // get amount of items by type and status
        const filteredTimeline = timeline.filter((timelineItem) => {
          let showByType = timelineItem.type === filterItem.id
          if (filterItem.id === ETimelinePostType.course) {
            showByType = !!timelineItem.course
          }

          let isShowByFilter = true
          if (status) {
            isShowByFilter =
              status.filter((filterValue) => {
                const value = TimelineFilterStatusMap[filterValue]
                return timelineItem.status === value
              }).length > 0
          }

          return showByType && isShowByFilter
        })
        amount = filteredTimeline.length

        // calculate amount of courses
        if (filterItem.id === ETimelinePostType.course) {
          const groupedTimeline = groupBy(filteredTimeline, 'course.id')
          delete groupedTimeline.undefined
          amount = Object.keys(groupedTimeline).length
        }
      }

      return {
        ...filterItem,
        label: amount > 0 ? `${filterItem.label} (${amount})` : filterItem.label,
      }
    })
  }, [status, timeline])

  const handleClickCheckbox = (value: Nullish<ETimelinePostType>[] | null) => {
    dispatch(setTimelineFiltersTypes(value))
  }

  return (
    <UIFilterDropdown<Nullish<ETimelinePostType>>
      buttonName="Type"
      checkboxes={checkboxes}
      value={timelineTypes}
      onClickCheckbox={handleClickCheckbox}
    />
  )
}
