import { UIFilterDropdown } from 'features/UI'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import {
  selectLearnLibraryStatusFilter,
  setLearnLibraryFilterStatus,
} from 'store/slice/learnLibraryFilters.slice'
import { ETimelinePostStatus, Nullish } from 'types'

const checkboxes = [
  {
    id: null,
    label: 'All',
  },
  {
    id: ETimelinePostStatus.new,
    label: 'New',
  },
  {
    id: ETimelinePostStatus.review,
    label: 'Review',
  },
  {
    id: ETimelinePostStatus.done,
    label: 'Done',
  },
]

export function LearnLibraryStatusDropdown() {
  const dispatch = useAppDispatch()
  const statusFilter = useSelector(selectLearnLibraryStatusFilter)

  const handleClickCheckbox = (value: Nullish<ETimelinePostStatus>[] | null) => {
    dispatch(setLearnLibraryFilterStatus(value))
  }

  return (
    <UIFilterDropdown<Nullish<ETimelinePostStatus>>
      buttonName="Read Status"
      checkboxes={checkboxes}
      value={statusFilter}
      onClickCheckbox={handleClickCheckbox}
    />
  )
}
