import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMetricType } from 'types/metric.types'
import { v4 as uuid } from 'uuid'
import { RootState } from '../store'

interface IEntityIds {
  id: number | null
  uuid: string | null
}

type IMetricState = {
  [key in IMetricType]: IEntityIds
}

const initialState: IMetricState = {
  [IMetricType.lesson]: {
    id: null,
    uuid: null,
  },
  [IMetricType.learnArticle]: {
    id: null,
    uuid: null,
  },
  [IMetricType.careArticle]: {
    id: null,
    uuid: null,
  },
  [IMetricType.questionnaire]: {
    id: null,
    uuid: null,
  },
}

export const metricSlice = createSlice({
  name: 'metric',
  initialState: initialState,
  reducers: {
    metricSetUuid: (state, action: PayloadAction<{ type: IMetricType; id: number }>) => {
      const { type, id } = action.payload
      state[type].id = id
      state[type].uuid = uuid()
    },
    metricClearUuid: (state, action: PayloadAction<{ type: IMetricType }>) => {
      state[action.payload.type] = { id: null, uuid: null }
    },
  },
})

export const { metricSetUuid, metricClearUuid } = metricSlice.actions

export const selectMetricSlice = (state: RootState): IMetricState => state.metric

export const selectMetricIds = (type?: IMetricType) =>
  createSelector(selectMetricSlice, (metric): IEntityIds | null => {
    if (!type) {
      return null
    }
    return metric[type]
  })
