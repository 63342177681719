import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLearnLibraries, getLearnLibrariesInReviewCount } from 'api/learnLibrary'
import { ETimelinePostStatus, LearnLibrary, LearnLibraryRequestGet, Nullish } from 'types'
import { RootState } from '../store'

export const getTopics = createAsyncThunk(
  'getLearnLibraries',
  async (
    filters: {
      status: ETimelinePostStatus[]
      selectedTagIds: Nullish<number[]>
    },
    thunkAPI,
  ) => {
    try {
      const filterRequest: LearnLibraryRequestGet = {
        filter: {
          status: {
            type: 'text',
            op: 'in',
            value: filters.status,
          },
        },
      }

      if (filters.selectedTagIds !== null && filterRequest.filter) {
        filterRequest.filter.tagId = {
          type: 'number',
          op: 'in',
          value: filters.selectedTagIds,
        }
      }

      const response = await getLearnLibraries(filterRequest)
      return response.data.rows
    } catch (e) {
      thunkAPI.rejectWithValue([])
    }
  },
)

export const getTopicsInReview = createAsyncThunk('getTopicsInReview', async (_, thunkAPI) => {
  try {
    const response = await getLearnLibrariesInReviewCount()
    return response.data
  } catch (e) {
    thunkAPI.rejectWithValue([])
  }
})

type LearnLibraryState = {
  learnLibraries: LearnLibrary[]
  learnLibrariesInReview: number
  isLoading: boolean
  loadingFailed: boolean
}

export const learnLibrarySlice = createSlice({
  name: 'learn-library',
  initialState: {
    learnLibraries: [],
    isLoading: false,
    loadingFailed: false,
    learnLibrariesInReview: 0,
  } as LearnLibraryState,
  reducers: {
    changeStatus: (
      state,
      action: PayloadAction<{ learnArticleId: number; status: ETimelinePostStatus }>,
    ) => {
      const learnLibrary = state.learnLibraries.find(
        (l) => l.learnArticleId === action.payload.learnArticleId,
      )
      if (learnLibrary) {
        learnLibrary.status = action.payload.status
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopics.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTopics.fulfilled, (state, action) => {
        state.isLoading = false
        state.learnLibraries = action.payload ?? []
        state.loadingFailed = false
      })
      .addCase(getTopics.rejected, (state) => {
        state.isLoading = false
        state.learnLibraries = []
      })
      .addCase('currentUser/logout', (state) => {
        state.learnLibraries = []
      })
      .addCase(getTopicsInReview.fulfilled, (state, action) => {
        state.learnLibrariesInReview = action.payload ?? 0
      })
      .addCase(getTopicsInReview.rejected, (state) => {
        state.learnLibrariesInReview = 0
      })
  },
})

export const { changeStatus } = learnLibrarySlice.actions

export const selectLearnLibrarySlice = (state: RootState) => state.learnLibrary

export const selectLearnLibraryReviewCount = createSelector(
  selectLearnLibrarySlice,
  (slice) => slice.learnLibrariesInReview,
)
