import { ColDef, ValueGetterParams } from 'ag-grid-community'
import columnRenderers from 'components/column-renderers'
import { CSVField } from 'hooks/useExportCSV'
import { ETrainingPathwayPointType, Pathway, PathwayGroupedPoint } from 'types'
import { formatDate } from 'utils'

const getNameValue = (point?: PathwayGroupedPoint) => {
  if (point?.type === 'course') {
    return point.name
  }

  if (point?.query) {
    return point.query.name
  }

  if (point?.careArticle) {
    return point.careArticle.name
  }

  if (point?.lesson) {
    return point.lesson.name
  }

  if (point?.questionnaire) {
    return point.questionnaire.name
  }

  return 'N/A'
}

const getTypeValue = (point?: PathwayGroupedPoint) => {
  switch (point?.type) {
    case ETrainingPathwayPointType.careArticle:
      return 'Timeline Post'
    case ETrainingPathwayPointType.lesson:
      return 'Lesson'
    case ETrainingPathwayPointType.query:
      return 'Query'
    case ETrainingPathwayPointType.questionnaire:
      return 'Exam'
    case 'course':
      return 'Course'
    default:
      return ''
  }
}

export function getPathwayColumns(): ColDef[] {
  return [
    {
      headerName: 'Name',
      flex: 1,
      rowDrag: true,
      valueGetter: ({ data: point }: ValueGetterParams<PathwayGroupedPoint>) => {
        return getNameValue(point)
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      flex: 1,
      valueGetter: ({ data: point }: ValueGetterParams<PathwayGroupedPoint>) => {
        return getTypeValue(point)
      },
    },
    {
      headerName: 'Creating Date',
      cellRenderer: columnRenderers.date('createdAt'),
      colId: 'createdAt',
      flex: 1,
    },
    {
      headerName: 'Last Edited',
      cellRenderer: columnRenderers.date('updatedAt'),
      minWidth: 220,
      colId: 'updatedAt',
      flex: 1,
    },
  ]
}

export function getPathwayCsvColumns(): CSVField[] {
  return [
    {
      label: 'Name',
      value: (point: PathwayGroupedPoint) => getNameValue(point),
    },
    {
      label: 'Type',
      value: (point: PathwayGroupedPoint) => getTypeValue(point),
    },
    {
      label: 'Creating Date',
      value: (data: Pathway) => formatDate(data.createdAt, 'YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'Last Edited',
      value: (data: Pathway) => formatDate(data.updatedAt, 'YYYY-MM-DD HH:mm:ss'),
    },
  ]
}
