import axios, { AxiosError } from 'axios'
import { clearLocalStorage, TOKEN } from 'utils'
import NotificationSys from '../components/NotificationSystem'

const SESSION_IS_EXPIRED = (
  <>
    Your session has expired.
    <br /> Please re-login
  </>
)

const productionBackend = 'https://pams-production-backend-docker.azurewebsites.net'
const stagingBackend = 'https://pams-staging-backend-docker.azurewebsites.net'
const developmentBackend = 'https://pams-development-backend-docker.azurewebsites.net'
const localBackend = 'http://localhost:5000'
const mstupakovBackend = 'http://pams.local:5000'

export const getBaseURL = () => {
  const host = window.location.hostname
  if (host === 'memorycarepartner.com') {
    return productionBackend
  }
  if (host === 'pams-staging.vvtdev.com') {
    return stagingBackend
  }
  if (host === 'pams-development.vvtdev.com') {
    return developmentBackend
  }
  if (host === 'localhost') {
    return process.env.REACT_APP_API_URL
  }
  if (host === 'pams.local') {
    return mstupakovBackend
  }
  throw new Error('There is no server API for this host')
}

export const httpHeaders: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const defaultOptions = {
  baseURL: getBaseURL(),
  headers: httpHeaders,
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN)
  config.headers = {
    ...config.headers,
    authorization: `Bearer ${token}`,
  }
  return config
})

type Headers = { get: (value: string) => string | undefined }

axiosInstance.interceptors.response.use(
  (response) => {
    const token = (response?.headers as Headers)?.get('X-Token')
    if (token) {
      localStorage.setItem(TOKEN, token)
    }
    return response
  },
  (error: AxiosError<{ message: string }>) => {
    const status: number | null = error.response ? error.response.status : null
    const url: string | undefined = error.request?.responseURL

    if (
      !error.config?.suppressErrorHandler ||
      !error.config?.suppressErrorHandler.includes(error.response?.status || 0)
    ) {
      if (status === 401) {
        clearLocalStorage()
        NotificationSys.showWarning(SESSION_IS_EXPIRED)

        // routers and routes must be imported dynamically
        // otherwise circular reference occurs
        import('../routes/router').then((router) => {
          import('../routes/routes').then((routes) => {
            router.router.navigate(routes.routes.signIn)
          })
        })
        return
      } else if ((status === 422 || status === 400) && error.response?.data.message) {
        NotificationSys.showWarning(error.response?.data.message)
      } else {
        NotificationSys.showWarning('Server returned an error')
      }
    }

    return Promise.reject(error)
  },
)

axios.defaults.baseURL = ''

export default axiosInstance
