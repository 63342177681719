import {
  BaseTableRequest,
  RequestById,
  Response,
  TableResponse,
  User,
  UserCaregiverUpdateRequest,
  UserConfirmPathwayChangesRequest,
  UserHistoryRow,
  UserModel,
  UserUpdateNoticeData,
  UserUpdateRequest,
} from 'types'
import axiosInstance from './axios'

export const getUsers = async (request: BaseTableRequest): Promise<TableResponse<User[]>> => {
  return axiosInstance.post('/users', request)
}

export const getUser = async (request: RequestById): Promise<Response<UserModel>> => {
  return axiosInstance.post('/users/read', request)
}

export const checkUpdateUser = async (
  request: UserUpdateRequest,
): Promise<Response<UserUpdateNoticeData[]>> => {
  return axiosInstance.post('/users/can-update', request)
}

export const updateUser = async (request: UserUpdateRequest): Promise<Response<UserModel>> => {
  return axiosInstance.post('/users/update', request)
}

export const updateCaregiverUser = async (
  request: UserCaregiverUpdateRequest,
): Promise<Response<UserModel>> => {
  return axiosInstance.post('/users/update', request)
}

export const confirmUserPathwayChanges = async (
  request: UserConfirmPathwayChangesRequest,
): Promise<Response<UserModel>> => {
  return axiosInstance.post('/users/update', request)
}

export const deleteUser = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/users/delete', request)
}

export const getUserHistory = async (
  request: BaseTableRequest,
): Promise<TableResponse<UserHistoryRow>> => {
  return axiosInstance.post('/users/histories', request)
}
