import { Stack, Typography } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UIStatusBadge } from 'features/UI'
import React from 'react'
import { ArticleType, ETimelinePostStatus } from 'types'
import { isDefined } from 'utils'

export function LibraryArticleItem({
  id,
  title,
  status,
  icon,
}: {
  id: number | undefined
  title: string
  icon: string | null
  status?: ETimelinePostStatus
}) {
  const { handleOpenViewArticle } = useDrawersContext()

  const handleOpen = () => {
    handleOpenViewArticle(Number(id), -1, ArticleType.LearnLibrary)
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: 'white',
        padding: 1.5,
        borderRadius: 2,
        width: '100%',
        cursor: 'pointer',
        gap: '8px',
      }}
      onClick={handleOpen}
    >
      {icon && (
        <img
          style={{
            verticalAlign: 'middle',
            maxWidth: 60,
            maxHeight: 60,
          }}
          src={icon}
          width="60px"
          height="60px"
          alt=""
        />
      )}

      <Typography fontWeight={400} fontSize="1.125rem" flexGrow={1}>
        {title}
      </Typography>

      {isDefined(status) && <UIStatusBadge status={status} />}
    </Stack>
  )
}
