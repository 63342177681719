import styled from '@emotion/styled'
import AssignmentIcon from '@mui/icons-material/Assignment'
import BookIcon from '@mui/icons-material/Book'
import ForumIcon from '@mui/icons-material/Forum'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SpeedIcon from '@mui/icons-material/Speed'
import { Box, List, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useAuthContext } from 'components/context/AuthProvider'
import { NumberBadge } from 'components/number-badge/NumberBadge'
import HelpTooltip from 'components/tooltips/HelpTooltip'
import { useEffectOnce } from 'hooks/useEffectOnce'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { routes } from 'routes/routes'
import { useActions, useAppDispatch, useAppSelector } from 'store/hooks'
import { selectLearnLibraryReviewCount } from 'store/slice/learn-library.slice'
import {
  selectLearnLibraryStatusFilter,
  setLearnLibraryFilterStatus,
} from 'store/slice/learnLibraryFilters.slice'
import { closeSidebar } from 'store/slice/navigation.slice'
import { selectTimelineFilter, setTimelineFilters } from 'store/slice/timelineFilters.slice'
import { ETimelinePostStatus, TimelineFilter, UserType } from 'types'
import { isSimpleArrayEquals } from 'utils/array'
import {
  actionsPlanTooltip,
  careJourneyTooltip,
  traineeJourneyTooltip,
  traineeLearnLibraryTooltip,
  traineeProgressTooltip,
} from '../../tooltips/tooltips'
import NavItem from '../common/NavItem'

const NewItemsDiv = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ClientNavigationItem = ({
  icon = <SpeedIcon />,
  path,
  tooltip,
  children,
  newItems,
  disabled,
  isNewItemsBadgeActive = false,
  onNewItemsClick,
}: React.PropsWithChildren<{
  icon?: React.ReactNode
  path: string
  tooltip: string
  newItems?: number
  disabled?: boolean
  isNewItemsBadgeActive?: boolean
  onNewItemsClick?: () => void
}>) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const handleClick = () => {
    if (!matchDownLG) {
      return
    }

    dispatch(closeSidebar())
  }

  return (
    <HelpTooltip placement="right" text={tooltip}>
      <Box onClick={handleClick}>
        <NavItem icon={icon} path={path} disabled={disabled}>
          {children}
          {!!newItems && (
            <NewItemsDiv>
              <NumberBadge
                value={newItems}
                active={isNewItemsBadgeActive}
                onClick={onNewItemsClick}
              />
            </NewItemsDiv>
          )}
        </NavItem>
      </Box>
    </HelpTooltip>
  )
}

const Journey = ({ text, tooltip }: { text: string; tooltip: string }) => {
  const dispatch = useAppDispatch()
  const timeLines = useAppSelector((s) => s.timeline.timeline) || []
  const timelineFilter = useSelector(selectTimelineFilter)

  const newItems = timeLines
    .filter((s) => !!s)
    .filter((s) => s?.status === ETimelinePostStatus.review).length

  const isReviewFilter = useMemo(() => {
    if (!timelineFilter) {
      return false
    }

    return isSimpleArrayEquals(timelineFilter, [TimelineFilter.Review])
  }, [timelineFilter])

  const handleNewItemsClick = () => {
    const filterValue = isReviewFilter ? null : [TimelineFilter.Review]
    dispatch(setTimelineFilters(filterValue))
  }

  return (
    <ClientNavigationItem
      path={routes.journey}
      tooltip={tooltip}
      newItems={newItems}
      icon={<ForumIcon />}
      isNewItemsBadgeActive={isReviewFilter}
      onNewItemsClick={handleNewItemsClick}
    >
      {text}
    </ClientNavigationItem>
  )
}

const CareJourney = () => {
  return <Journey text="Care Journey" tooltip={careJourneyTooltip} />
}

const TrainingJourney = () => {
  return <Journey text="Training Journey" tooltip={traineeJourneyTooltip} />
}

const LearnLibrary = () => {
  const dispatch = useAppDispatch()
  const { getTopicsInReview } = useActions()
  const statusFilter = useSelector(selectLearnLibraryStatusFilter)
  const reviewCount = useSelector(selectLearnLibraryReviewCount)

  const isBadgeActive = useMemo(() => {
    if (!statusFilter) {
      return false
    }

    return isSimpleArrayEquals(statusFilter, [ETimelinePostStatus.review])
  }, [statusFilter])

  const handleNewItemsClick = useCallback(() => {
    const filterValue = isBadgeActive
      ? null // all statuses
      : [ETimelinePostStatus.review]
    dispatch(setLearnLibraryFilterStatus(filterValue))
  }, [dispatch, isBadgeActive])

  useEffectOnce(() => {
    getTopicsInReview()
  })

  return (
    <ClientNavigationItem
      path={routes.learnLibrary}
      tooltip={traineeLearnLibraryTooltip}
      icon={<BookIcon />}
      newItems={reviewCount}
      isNewItemsBadgeActive={isBadgeActive}
      onNewItemsClick={handleNewItemsClick}
    >
      Learn Library
    </ClientNavigationItem>
  )
}

const Progress = () => {
  return (
    <ClientNavigationItem
      tooltip={traineeProgressTooltip}
      path={routes.progress}
      icon={<AssignmentIcon />}
    >
      Progress
    </ClientNavigationItem>
  )
}

const ActionPlan = () => {
  return (
    <ClientNavigationItem
      tooltip={actionsPlanTooltip}
      path={routes.actionPlan}
      disabled
      icon={<ListAltIcon />}
    >
      Action Plan
    </ClientNavigationItem>
  )
}

const TraineeNavigation = () => {
  return (
    <>
      <TrainingJourney />
      <LearnLibrary />
      <Progress />
    </>
  )
}

const CaregiverNavigation = () => {
  return (
    <>
      <CareJourney />
      <LearnLibrary />
      <ActionPlan />
    </>
  )
}

const CareteamNavigation = () => {
  return (
    <>
      <CareJourney />
      <LearnLibrary />
    </>
  )
}

export const ClientNavigation = () => {
  const { user } = useAuthContext()
  if (!user) {
    return null
  }

  return (
    <List disablePadding={true}>
      {user.currentType === UserType.TRAINEE ? (
        <TraineeNavigation />
      ) : user.currentType === UserType.CAREGIVER ? (
        <CaregiverNavigation />
      ) : user.currentType === UserType.CARETEAM ? (
        <CareteamNavigation />
      ) : null}
    </List>
  )
}

export default ClientNavigation
