import { useCallback } from 'react'
import { ArticleType } from 'types'
import { useDrawersContext } from '../../context/ClientsDrawersProvider'

const useLearnMore = (id: number) => {
  const { handleOpenViewArticle } = useDrawersContext()

  const learnMoreClicked = useCallback(() => {
    handleOpenViewArticle(id, null, ArticleType.LearnLibrary)
  }, [handleOpenViewArticle, id])

  return [learnMoreClicked]
}

export default useLearnMore
