// @ts-ignore
import { stringExcel } from '@json2csv/formatters'
// @ts-ignore
import { Parser } from '@json2csv/plainjs'
import { ColumnApi } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController'
import FileSaver from 'file-saver'
import { useCallback, useState } from 'react'
import { BaseTableRequest } from 'types'
import { prepareTableParams } from 'utils'

type Func = (req: BaseTableRequest) => Promise<{
  data: {
    rows: object[]
    count: number
  }
}>

export interface CSVField {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | ((record: any) => string)
}

const delimiter = ','

export const useExportCSV = (
  func: Func,
  gridApi: GridApi | null,
  columnApi: ColumnApi | null,
  fields?: CSVField[],
  filename?: string,
) => {
  const [loading, setLoading] = useState(false)

  const onExportCSV = useCallback(async () => {
    if (!gridApi || !columnApi) {
      return
    }
    const filterModel = gridApi.getFilterModel()
    const columnState = columnApi.getColumnState()

    const sortModel: SortModelItem[] = columnState
      .filter((it) => it.sort)
      .map((it) => ({
        colId: it.colId,
        sort: it.sort as 'desc',
      }))

    const params = {
      filterModel,
      sortModel,
    }
    try {
      setLoading(true)
      const json = await func(prepareTableParams(params))

      const opts = {
        delimiter,
        fields,
        formatters: {
          string: stringExcel,
        },
      }
      const parser = new Parser(opts)
      const csv = parser.parse(json.data.rows)
      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, filename || 'report.csv')
    } catch (e: unknown) {
    } finally {
      setLoading(false)
    }
  }, [columnApi, fields, filename, func, gridApi])

  return { csvLoading: loading, onExportCSV }
}
