import CircularProgress from '@mui/material/CircularProgress'
import React, { useRef, useState } from 'react'
import YouTube from 'react-youtube'
import { useMetric } from 'services/Metric'
import { IMetricScope, IMetricType } from 'types/metric.types'

const YoutubeWrapper = ({
  videoId,
  metricType,
  metricEntityId,
  onSaveMetric,
}: {
  videoId: string
  metricType?: IMetricType
  metricEntityId?: number
  onSaveMetric: (value: number, uuid: string, scope: IMetricScope) => void
}) => {
  const [isVisible, setVisible] = useState(false)
  const [isLinearVisible, setLinearVisible] = useState(true)
  const isPlaying = useRef(false)

  const { onMetricTimerStart, onMetricSave } = useMetric({
    type: metricType,
    entityId: metricEntityId,
    scope: IMetricScope.video,
    hasIdleTimer: false,
    isImmediateStart: false,
    onSave: (value, uuid, scope, isDestroyed: boolean) => {
      if (!isDestroyed || (isDestroyed && isPlaying.current)) {
        onSaveMetric?.(value, uuid, scope)
      }
    },
  })

  const onReady = () => {
    setVisible(true)
    setTimeout(() => {
      setLinearVisible(false)
    }, 3000)
  }

  const onPlay = () => {
    isPlaying.current = true
    onMetricTimerStart()
  }

  const onPause = () => {
    isPlaying.current = false
    onMetricSave()
  }

  const onEnd = () => {
    isPlaying.current = false
    onMetricSave()
  }

  return (
    <div className="block-wrapper">
      <div className="video-wrapper">
        <CircularProgress
          style={{
            display: isLinearVisible ? 'block' : 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 48,
            height: 48,
          }}
        />
        <YouTube
          videoId={videoId} // defaults -> ''
          //id={string}                       // defaults -> ''
          //className={string}                // defaults -> ''
          //iframeClassName={string}          // defaults -> ''
          style={{ display: isVisible ? 'block' : 'none' }} // defaults -> {}
          //title={string}                    // defaults -> ''
          //loading={string}                  // defaults -> undefined
          opts={{
            height: '450px',
            width: '100%',
          }} // defaults -> {}
          onReady={onReady} // defaults -> noop
          onPlay={onPlay} // defaults -> noop
          onPause={onPause} // defaults -> noop
          onEnd={onEnd} // defaults -> noop
          // onError={func}                    // defaults -> noop
          // onStateChange={func}              // defaults -> noop
          // onPlaybackRateChange={func}       // defaults -> noop
          // onPlaybackQualityChange={func}    // defaults -> noop
        />
      </div>
    </div>
  )
}

export default React.memo(YoutubeWrapper)
