import { UIFilterDropdown } from 'features/UI'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { selectTimelineFilter, setTimelineFilters } from 'store/slice/timelineFilters.slice'
import { Nullish, TimelineFilter } from 'types'

const filterItems = [
  {
    id: null,
    label: 'All',
  },
  {
    id: TimelineFilter.New,
    label: 'New',
  },
  {
    id: TimelineFilter.Review,
    label: 'Review',
  },
  {
    id: TimelineFilter.Done,
    label: 'Done',
  },
]

export function TimelineFilterByStatus() {
  const dispatch = useAppDispatch()
  const timelineFilter = useSelector(selectTimelineFilter)

  const handleClickCheckbox = (value: Nullish<TimelineFilter>[] | null) => {
    dispatch(setTimelineFilters(value))
  }

  return (
    <UIFilterDropdown<Nullish<TimelineFilter>>
      buttonName="Read Status"
      checkboxes={filterItems}
      value={timelineFilter}
      onClickCheckbox={handleClickCheckbox}
    />
  )
}
