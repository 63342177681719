import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { defaultModalStyle, defaultModalZIndex, minButtonWidth } from '../../const'
import { LoadingButton } from '@mui/lab'
import { isDefined } from '../../utils'
import AudioPlayer from '../audio/AudioPlayer'
import { downloadMediaFile } from '../../api/mediaFiles'
import LinearProgressWithLabel from '../LinearProgressWithLabel'

interface Props {
  isOpen: boolean
  handleClose: () => void

  title: string
  errorText?: string
  buttonText?: string

  value?: number | null
  onSubmit: (file: File) => void
  loading?: boolean
  progressBarValue?: number
}

const modalStyle = {
  ...defaultModalStyle,
  width: 420,
}

const ModalContent = (props: Props) => {
  const {
    value,
    handleClose,
    title,
    errorText = 'Please select audio file before update',
    onSubmit,
    loading,
    progressBarValue,
  } = props

  const [error, setError] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)

  const onChangeFile = (event: React.ChangeEvent) => {
    const file = (event as unknown as BaseSyntheticEvent<File>).target.files[0]
    setFile(file)
    setError(null)
  }

  const onSubmitWrapper = () => {
    if (file) {
      onSubmit(file)
    } else {
      setError(errorText)
    }
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onClickUpload = () => {
    inputRef.current?.click()
  }

  const [oldSrc, setOldSrc] = useState<string | null>(null)

  useEffect(() => {
    const load = async () => {
      if (value) {
        const url = await downloadMediaFile(value)
        setOldSrc(url)
      }
    }
    load()
  }, [value])

  const newFileSrc = isDefined(file) && URL.createObjectURL(file)

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" mb={3}>
        {title}
      </Typography>

      <Box width="100%">
        <Stack minHeight="72px">
          <input
            ref={inputRef}
            onChange={onChangeFile}
            id="audio-file"
            type="file"
            accept="audio/wav, audio/mpeg, audio/mp4, audio/ogg, audio/aacp, audio/flac"
            style={{ display: 'none' }}
          />

          <Button variant="contained" onClick={onClickUpload}>
            Upload file
          </Button>

          {isDefined(value) && !newFileSrc && (
            <Typography mt={1}>Previously uploaded audio</Typography>
          )}

          {file && (
            <Stack mt={1}>
              <Typography variant="body1" fontWeight={400}>
                Uploading audio file:
              </Typography>
              <Typography variant="body1" fontWeight={400}>
                {file.name}
              </Typography>
            </Stack>
          )}

          {(oldSrc || newFileSrc) && (
            <Box mt={1.5}>
              {!newFileSrc && oldSrc && <AudioPlayer src={oldSrc} />}
              {newFileSrc && <AudioPlayer src={newFileSrc} />}
            </Box>
          )}

          {error && (
            <Typography color="error" variant="subtitle2" fontWeight={400} mt={1}>
              {error}
            </Typography>
          )}
          {loading && (
            <Box mt={0.5}>
              <LinearProgressWithLabel value={progressBarValue || 0} />
            </Box>
          )}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth} disabled={loading}>
            Close
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            sx={minButtonWidth}
            onClick={onSubmitWrapper}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  )
}

const UploadAudioModal = (props: Props) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      {isOpen ? <ModalContent {...props} /> : <Box />}
    </Modal>
  )
}

export default React.memo(UploadAudioModal)
