import { AxiosProgressEvent } from 'axios'
import { BaseTableRequest, MediaFile, RequestById, Response, TableResponse } from 'types'
import axiosInstance from './axios'

export const getMediaFiles = async (
  request: BaseTableRequest,
): Promise<TableResponse<MediaFile>> => {
  return axiosInstance.post('/media-files', request)
}

export const createMediaFile = async (
  file: File,
  onUploadProgress: (e: AxiosProgressEvent) => void,
): Promise<Response<MediaFile>> => {
  const formData = new FormData()
  formData.append('mediaFile', file)
  return await axiosInstance.post('/media-files/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })
}

export const deleteMediaFileById = async (id: number): Promise<Response<MediaFile>> => {
  return axiosInstance.post('/media-files/delete', { id })
}

export const getMediaFileById = async (request: RequestById): Promise<Response<MediaFile>> => {
  return axiosInstance.post('/media-files/read', request)
}

export const downloadMediaFile = async (id: number): Promise<string> => {
  const response = await axiosInstance.get(`/media-files/download/${id}`, {
    responseType: 'arraybuffer',
  })
  const blob = new Blob([response.data])
  return URL.createObjectURL(blob)
}
