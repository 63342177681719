import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { ArticleType, LessonTimelineItem } from 'types'
import { TimelineCourseChildItem } from '../TimelineCourseChildItem/TimelineCourseChildItem'

export function TimelineLessonItem({ item }: { item: LessonTimelineItem }) {
  const { handleOpenViewArticle } = useDrawersContext()

  const onClickArticle = () => {
    handleOpenViewArticle(item.lessonId, item.id, ArticleType.Lesson)
  }

  return (
    <TimelineCourseChildItem
      name={item.lesson.post || item.lesson.name}
      bannerUrl={item.lesson.bannerUrl}
      status={item.status}
      buttonTitle="View"
      onClick={onClickArticle}
    />
  )
}
