import styled from '@emotion/styled'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Stack, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import React, { useEffect, useRef, useState } from 'react'
import { useMetric } from 'services/Metric'
import { IMetricScope, IMetricType } from 'types/metric.types'
import { useControlBoolean } from '../../hooks/useControlBoolean'

const Range = styled.input`
  height: 7px;
  appearance: none;
  border-radius: 8px;
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
  flex-grow: 1;
  margin-left: 8px;

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #ecf0f1;
    border: 2px solid #777;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    background: #ecf0f1;
    border: 2px solid #777;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
  }
`

const iconSX = { fontSize: '2.25rem', color: red[600], cursor: 'pointer' }

const formatTime = (num: number) => {
  const fixed = Math.trunc(num)
  const sec = String(fixed % 60).padStart(2, '0')
  const minutes = String(Math.trunc(fixed / 60)).padStart(2, '0')
  return `${minutes}:${sec}`
}

const AudioPlayer = ({
  src,
  mediaFileMimeType,
  metricEntityId,
  metricType,
  onSaveMetric,
}: {
  src: string
  mediaFileMimeType?: string
  metricEntityId?: number
  metricType?: IMetricType
  onSaveMetric?: (value: number, uuid: string, scope: IMetricScope) => void
}) => {
  const [trackProgress, setTrackProgress] = useState(0)
  const [isPlay, togglePlay, togglePause] = useControlBoolean()
  const isPlaying = useRef(false)
  const [isPlayed, setIsPlayed] = useState(false)
  const { onMetricTimerStart, onMetricSave } = useMetric({
    type: metricType,
    entityId: metricEntityId,
    scope: IMetricScope.audio,
    isImmediateStart: false,
    hasIdleTimer: false,
    onSave: (value: number, uuid: string, scope: IMetricScope, isDestroyed: boolean) => {
      if (!isDestroyed || (isDestroyed && isPlaying.current)) {
        onSaveMetric?.(value, uuid, scope)
      }
    },
  })

  const audioRef = useRef(new Audio(src))
  const intervalRef = useRef<NodeJS.Timer>()

  const { duration } = audioRef.current

  const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%'
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #333), color-stop(${currentPercentage}, #777))
  `

  const startTimer = () => {
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (!audioRef.current.ended) {
        setTrackProgress(audioRef.current.currentTime)
      } else {
        setTrackProgress(duration)
        togglePause()
      }
    }, 1000)
  }

  const onScrub = (value: number) => {
    clearInterval(intervalRef.current)
    audioRef.current.currentTime = value
    setTrackProgress(audioRef.current.currentTime)
  }

  const onScrubEnd = () => {
    if (!isPlay) {
      togglePlay()
    }
    startTimer()
  }

  useEffect(() => {
    if (isPlay) {
      isPlaying.current = true
      audioRef.current.play()
      setIsPlayed(true)
      startTimer()
      onMetricTimerStart()
    } else {
      isPlaying.current = false
      audioRef.current.pause()
      if (isPlayed) {
        onMetricSave()
      }
    }
  }, [isPlay, isPlayed]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    audioRef.current.pause()
    if (!mediaFileMimeType) {
      audioRef.current = new Audio(src)
    } else {
      audioRef.current = new Audio()
      const source = document.createElement('source')
      source.setAttribute('src', src)
      source.setAttribute('type', mediaFileMimeType)
      audioRef.current.append(source)
    }

    setTrackProgress(audioRef.current.currentTime)
  }, [mediaFileMimeType, src, togglePlay])

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause()
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <Stack flexDirection="row" alignItems="center">
      {isPlay ? (
        <PauseCircleIcon onClick={togglePause} sx={iconSX} />
      ) : (
        <PlayCircleIcon onClick={togglePlay} sx={iconSX} />
      )}
      <Range
        type="range"
        value={trackProgress}
        step="0.25"
        min="0"
        max={duration ? duration : `${duration}`}
        onChange={(e) => onScrub(Number(e.target.value))}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: trackStyling }}
      />
      <Typography ml={1} mb={-0.25} color="textSecondary">
        {formatTime(trackProgress)} / {formatTime(duration || 0)}
      </Typography>
    </Stack>
  )
}

export default React.memo(AudioPlayer)
