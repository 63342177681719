/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import DropdownButton from 'components/buttons/DropdownButton'
import { PRIMARY_ID_MISSING } from 'const'

export type RowActionRenderer = {
  renderName: (value: ICellRendererParams<any>) => string
  onClick: (id: number, props: ICellRendererParams<any>) => void
}

export type RowAction =
  | {
      name: string
      onClick: (id: number, props: ICellRendererParams<any>) => void
    }
  | RowActionRenderer

export const getActionColumn = (rowActions: RowAction[], idField: string = 'id'): ColDef => {
  return {
    headerName: 'Action',
    pinned: 'right',
    suppressMovable: true,
    filter: null,
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    resizable: false,
    cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    cellRenderer: (props: ICellRendererParams<{ [idField: string]: number }>) => {
      const itemId = props?.data?.[idField]

      const actions = rowActions.map((it) => {
        let name = ''
        if ('name' in it) {
          name = it.name
        }
        if ('renderName' in it) {
          name = it.renderName(props)
        }

        return {
          name: name,
          onClick: () => {
            if (!itemId) {
              console.error(PRIMARY_ID_MISSING)
              return
            }
            it.onClick(itemId, props)
          },
        }
      })

      return (
        <DropdownButton buttonTitle="Actions" variant="outlined">
          {actions.map((action) => (
            <MenuItem key={action.name} onClick={action.onClick}>
              {action.name}
            </MenuItem>
          ))}
        </DropdownButton>
      )
    },
  }
}
