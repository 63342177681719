import { Button, Stack } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UICarePostArticleImg } from 'features/UI'
import React, { useMemo } from 'react'
import { ArticleType, ETimelinePostStatus } from 'types'
import { TimelineItemCard } from '../TimelineItemCard/TimelineItemCard'
import { TimelineLine } from '../TimelineLine/TimelineLine'
import { TimelinePhotoPreview } from '../TimelinePhotoPreview/TimelinePhotoPreview'

export function TimelineArticleItem({
  id,
  title,
  type,
  timelineId,
  status,
  bannerUrl,
}: {
  timelineId: number
  id: number
  title: string
  type: ArticleType
  status: ETimelinePostStatus
  quizId?: number
  bannerUrl?: string | null
}) {
  const { handleOpenViewArticle } = useDrawersContext()

  const onClickArticle = () => {
    handleOpenViewArticle(id, timelineId, type)
  }

  const preview = useMemo(() => {
    if (!bannerUrl) return null

    return <TimelinePhotoPreview url={bannerUrl} />
  }, [bannerUrl])

  return (
    <Stack direction="row" spacing={2}>
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        <UICarePostArticleImg isOnTimeline />
        <TimelineLine />
      </div>

      <TimelineItemCard
        title={title}
        status={status}
        onClick={onClickArticle}
        preview={preview}
        controls={
          <Button size="small" variant="contained" fullWidth onClick={onClickArticle}>
            View
          </Button>
        }
      />
    </Stack>
  )
}
