import { useCallback, useState } from 'react'

type Out = [boolean, () => void, () => void]

export const useControlBoolean = (): Out => {
  const [state, setState] = useState(false)

  const setTrue = useCallback(() => {
    setState(true)
  }, [])

  const setFalse = useCallback(() => {
    setState(false)
  }, [])

  return [state, setTrue, setFalse]
}
