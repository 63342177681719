import React, { useEffect, useMemo } from 'react'
import Drawer from '@mui/material/Drawer'
import { defaultDrawerSX } from '../../../const'
import { Box, Tab, Tabs } from '@mui/material'
import { getTitle } from '../../tabs/TabsTitle'
import { useTabsControl } from '../../../hooks/useTabsControl'
import { grey } from '@mui/material/colors'
import ProfileBasicInfoForm from './forms/ProfileBasicInfoForm'
import ProfileTrainingDetailsForm from './forms/ProfileTrainingDetailsForm'
import DrawerContainer from '../common/DrawerContainer'
import ProfileBanner from '../article/ProfileBanner'
import DrawerHeader from '../common/DrawerHeader'
import { useAuthContext } from '../../context/AuthProvider'

interface Props {
  isOpen: boolean
  handleClose: () => void
  userId: number
}

const ProfileDrawer = (props: Props) => {
  const { isOpen, handleClose } = props

  const firstTab = <ProfileBasicInfoForm onClose={handleClose} userId={props.userId} />
  const secondTab = <ProfileTrainingDetailsForm onClose={handleClose} />

  const { selectedTab, handleChangeSelectedTab, setSelectedTab } = useTabsControl('first')

  const renderedTab = useMemo(() => {
    switch (selectedTab) {
      case 'first':
        return firstTab
      case 'second':
        return secondTab
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab('first')
    }
  }, [isOpen, setSelectedTab])

  const { profileName, isTrainee } = useAuthContext()

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      {isOpen ? (
        <DrawerContainer isOpen={isOpen} hasData loading={false} handleClose={handleClose}>
          <DrawerHeader handleClose={handleClose}>Your Training Membership Profile</DrawerHeader>

          <ProfileBanner profileName={profileName} typeOfProfile="caregiver" />

          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
            sx={{ background: grey[100] }}
          >
            <Tab value="first" label={getTitle('Basic Info')} />
            {isTrainee && <Tab value="second" label={getTitle('Training Details')} />}
          </Tabs>
          <Box mt={2}>{renderedTab}</Box>
        </DrawerContainer>
      ) : null}
    </Drawer>
  )
}

export default ProfileDrawer
