import Drawer from '@mui/material/Drawer'
import { getCareArticleById } from 'api/careArticles'
import { getLibraryArticleById } from 'api/learnArticles'
import { learnLibrarySetStatus as learnLibrarySetStatus } from 'api/learnLibrary'
import { getLessonById } from 'api/lessons'
import { setStatus } from 'api/timelines'
import NotificationSys from 'components/NotificationSystem'
import { defaultDrawerSX } from 'const'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import React, { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { useActions, useAppDispatch } from 'store/hooks'
import { selectLearnLibraryStatusFilter } from 'store/slice/learnLibraryFilters.slice'
import { metricClearUuid } from 'store/slice/metric.slice'
import { selectTagsFiltersIds } from 'store/slice/tagsFilters.slice'
import {
  allFiltersStatuses,
  ArticleType,
  CareArticle,
  ETimelinePostStatus,
  ETimelinePostType,
  IMetricType,
  LearnArticle,
  Lesson,
  Response,
} from 'types'
import DrawerContainer from '../../common/DrawerContainer'
import { ViewArticleDrawerContent } from './ViewArticleDrawerContent'

const convertArticleTypeToPostType = (articleType: ArticleType) => {
  switch (articleType) {
    case ArticleType.CareArticle:
      return ETimelinePostType.careArticle
    case ArticleType.Lesson:
      return ETimelinePostType.lessons
    case ArticleType.LearnLibrary:
      return ETimelinePostType.learnArticles
  }
}

const getLoadFunc = (type: ArticleType) => {
  switch (type) {
    case ArticleType.CareArticle:
      return getCareArticleById
    case ArticleType.LearnLibrary:
      return getLibraryArticleById
    case ArticleType.Lesson:
      return getLessonById
  }
}

const ViewArticleDrawer = ({
  isOpen,
  handleClose,
  id,
  type,
}: {
  id: number
  timelineId: number | null
  type: ArticleType
  isOpen: boolean
  handleClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const statusFilter = useSelector(selectLearnLibraryStatusFilter)
  const selectedTagIdsFilter = useSelector(selectTagsFiltersIds)

  const { data, loading } = useEntityDataControl({
    id,
    loadFunc: getLoadFunc(type) as () => Promise<Response<Lesson | LearnArticle | CareArticle>>,
    warning: "Can't load the data",
  })

  const { getTimelineById, learnLibraryChangeStatus, getTopicsInReview, getTopics } = useActions()

  const metricType = useMemo(() => {
    if (type === ArticleType.Lesson) {
      return IMetricType.lesson
    } else if (type === ArticleType.LearnLibrary) {
      return IMetricType.learnArticle
    } else if (type === ArticleType.CareArticle) {
      return IMetricType.careArticle
    }

    return undefined
  }, [type])

  const onDefaultClose = useCallback(() => {
    if (metricType) {
      dispatch(metricClearUuid({ type: metricType }))
    }
    handleClose()
  }, [dispatch, handleClose, metricType])

  const onClose = useCallback(
    (shouldClearUuid: boolean) => {
      if (shouldClearUuid && metricType) {
        dispatch(metricClearUuid({ type: metricType }))
      }
      handleClose()
    },
    [dispatch, handleClose, metricType],
  )

  const setStatusMutation = useMutation(setStatus, {
    onSuccess: () => {
      onDefaultClose()
      getTimelineById({})

      NotificationSys.showSuccess(`Article successfully updated`)
    },
  })

  const setLearnArticleStatus = useMutation(learnLibrarySetStatus, {
    onSuccess: ({ data }) => {
      onDefaultClose()
      getTimelineById({})
      getTopicsInReview()
      getTopics({
        status: statusFilter !== null ? statusFilter : allFiltersStatuses,
        selectedTagIds: selectedTagIdsFilter,
      })

      if (data.status == 'updated') {
        NotificationSys.showSuccess(`Article successfully updated`)
      } else if (data.status == 'created') {
        NotificationSys.showSuccess(
          'Article is successfully added to the timeline with selected status',
        )
      }
    },
  })

  const onUpdateStatus = (status: ETimelinePostStatus) => {
    if (type === ArticleType.LearnLibrary) {
      setLearnArticleStatus.mutate(
        { id, status },
        {
          onSuccess: () => {
            learnLibraryChangeStatus({ learnArticleId: id, status })
          },
        },
      )
    } else {
      setStatusMutation.mutate({
        id,
        status,
        type: convertArticleTypeToPostType(type),
      })
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onDefaultClose} sx={defaultDrawerSX}>
      <DrawerContainer
        isOpen={isOpen}
        hasData={!!data}
        loading={loading}
        handleClose={onDefaultClose}
      >
        {!loading && data && (
          <ViewArticleDrawerContent
            data={data}
            handleClose={onClose}
            onUpdateStatus={onUpdateStatus}
            type={type}
            showStatusChange={type === ArticleType.Lesson || type === ArticleType.CareArticle}
          />
        )}
      </DrawerContainer>
    </Drawer>
  )
}

export default ViewArticleDrawer
