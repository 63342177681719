import { Box, Stack } from '@mui/material'
import { ColDef, ICellRendererParams, RowDragEvent } from 'ag-grid-community'
import {
  deleteLibraryArticle,
  duplicateLearnArticle,
  getLibraryArticleById,
  updateLibraryArticle,
} from 'api/learnArticles'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import { createTagsColumn, useTagsFilterForDraggableTable } from 'features/Tags'
import { getActionColumn, UIContentIcons, UIMessageNoRows } from 'features/UI'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { noop } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { routes } from 'routes/routes'
import { LearnArticle } from 'types'
import { isDefined, withIdParam } from 'utils'
import { useLearnLibraryArticleMove } from '../../hooks/useLearnLibraryArticleMove'
import { LearnLibraryArticleDuplicateModal } from '../LearnLibraryArticleDuplicateModal/LearnLibraryArticleDuplicateModal'

export function LearnLibraryTopicGrid({
  articles,
  onDelete,
  onReorder,
  onPublish,
}: {
  articles: LearnArticle[]
  onDelete: (id: number) => void
  onReorder: (ids: number[]) => void
  onPublish: (id: number, published: boolean) => void
}) {
  const navigate = useNavigate()

  const [duplicateArticleId, setDuplicateArticleId] = useState<number>()

  const duplicateArticleMutation = useMutation(duplicateLearnArticle)
  const handleDuplicateArticle = useCallback(
    async (topicId: number) => {
      if (!isDefined(duplicateArticleId)) return

      duplicateArticleMutation.mutate(
        { id: duplicateArticleId, topicId },
        {
          onSuccess: (response) => {
            setDuplicateArticleId(undefined)
            navigate(withIdParam(routes.learnArticleEdit, response.data.id), {
              state: {
                notification: 'Lesson has been successfully duplicated',
              },
            })
          },
        },
      )
    },
    [duplicateArticleId, duplicateArticleMutation, navigate],
  )

  const handleRowDragEnd = useCallback(
    (e: RowDragEvent<LearnArticle>) => {
      const orderedPointIds = e.api
        .getRenderedNodes()
        .filter((node) => !!node.data)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((node) => node.data!.id)
      onReorder(orderedPointIds)
    },
    [onReorder],
  )

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteLibraryArticle,
      successCallback: () => {
        if (!isDefined(idToRemove)) return

        onDelete(idToRemove)
      },
      warning: "Can't remove the learn library topic",
    })

  const handleEditClick = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.learnArticleEdit, id))
    },
    [navigate],
  )

  const handleDeleteClick = useCallback(
    (id: number) => {
      openDeleteModal(id)
    },
    [openDeleteModal],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl({
    successCallback: (id: number, action: PublishAction) => {
      onPublish(id, action == PublishAction.Publish)
    },
    loadFunc: getLibraryArticleById,
    updateFunc: updateLibraryArticle,
  })

  const handlePublish = useCallback(
    (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
      openPublishModal(id, action)
    },
    [openPublishModal],
  )

  const handleDuplicateClicked = useCallback((id: number) => {
    setDuplicateArticleId(id)
  }, [])

  const { moveAction, MoveModal } = useLearnLibraryArticleMove()

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: handleEditClick,
      },
      {
        name: 'Delete',
        onClick: handleDeleteClick,
      },
      {
        renderName: publishActionRender,
        onClick: handlePublish,
      },
      {
        name: 'Duplicate',
        onClick: handleDuplicateClicked,
      },
      moveAction,
    ]
  }, [
    handleDeleteClick,
    handleDuplicateClicked,
    handleEditClick,
    handlePublish,
    publishActionRender,
    moveAction,
  ])

  const { TagFilter, rowData, isDraggable } = useTagsFilterForDraggableTable<LearnArticle>(
    articles,
    'tags',
  )

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Learn Library Article Name',
        rowDrag: isDraggable,
        field: 'name',
        minWidth: 260,
        sortable: false,
        cellRenderer: columnRenderers.createLink(
          {
            idSelector: (data) => data.id,
            nameSelector: (data) => data.name,
          },
          routes.learnArticleEdit,
        ),
      },
      {
        headerName: 'Link to Lesson Article',
        field: 'link',
        sortable: false,
        cellRenderer: columnRenderers.createLink(
          {
            nameSelector: (data) => data?.lesson?.name,
            idSelector: (data) => data?.lesson?.id,
          },
          routes.lessonEdit,
        ),
      },
      {
        headerName: 'Link to Timeline Post Article',
        minWidth: 260,
        field: 'link',
        sortable: false,
        cellRenderer: columnRenderers.createLink(
          {
            nameSelector: (data) => data.careArticle?.name,
            idSelector: (data) => data.careArticle?.id,
          },
          routes.timelinePostEdit,
        ),
      },
      {
        headerName: 'Content',
        sortable: false,
        cellRenderer: (props: ICellRendererParams<LearnArticle>) => {
          if (!props.data) return PLACEHOLDER

          return (
            <UIContentIcons
              isPublished={props.data.isPublished}
              hasImage={props.data.hasImage}
              hasVideo={props.data.hasVideo}
              hasAudio={props.data.hasAudio}
            />
          )
        },
      },
      {
        headerName: 'Query',
        field: 'queryId',
        sortable: false,
        cellRenderer: columnRenderers.checkbox(noop),
      },
      {
        headerName: 'Published',
        cellRenderer: columnRenderers.published,
        sortable: false,
      },
      createTagsColumn('tags'),
      {
        headerName: 'Creating Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
        sortable: false,
      },
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        colId: 'updatedAt',
        sortable: false,
      },
      getActionColumn(actions),
    ] satisfies ColDef[]
  }, [actions, isDraggable])

  return (
    <>
      {!!duplicateArticleId && (
        <LearnLibraryArticleDuplicateModal
          onClose={() => setDuplicateArticleId(undefined)}
          onSubmit={handleDuplicateArticle}
          loading={duplicateArticleMutation.isLoading}
        />
      )}

      {MoveModal}

      <ConfirmRemoveModal
        entityToRemove="Article"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />
      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Learn Library Article"
      />
      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        <Box minWidth="200px">{TagFilter}</Box>
      </Stack>
      <InfinityAgGrid<LearnArticle>
        columnDefs={columnDefs}
        rowDragManaged={isDraggable}
        animateRows
        rowModelType="clientSide"
        rowData={rowData}
        rowDragMultiRow
        rowSelection="single"
        rowDragEntireRow
        floatingFiltersHeight={0}
        noRowsOverlayComponent={() => <UIMessageNoRows message="No lessons on course" />}
        onRowDragEnd={handleRowDragEnd}
      />
    </>
  )
}
