import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { getPathways } from 'api/pathways'
import columnRenderers from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getActionColumn } from 'features/UI'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Pathway } from 'types'
import { formatDate, useGetDataSource, withIdParam } from 'utils'

export function PathwayPageList() {
  const navigate = useNavigate()

  const getDataSource = useGetDataSource(getPathways)
  const { gridApi, columnApi, onGridReady } = useGridControl(getDataSource)

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.pathwayEdit, id))
    },
    [navigate],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
    ]
  }, [onClickEdit])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Pathway Name',
        field: 'name',
      },
      {
        headerName: 'Pathway Type',
        field: 'trainingPathwayTypeName',
        minWidth: 270,
      },
      {
        headerName: 'New Records',
        cellRenderer: (params: ICellRendererParams<Pathway>) => {
          const count = params.data?.newTimelinePostCount
          return count === -1 ? 'All' : count
        },
      },
      {
        headerName: 'Overall Records',
        field: 'overallRecords',
      },
      {
        headerName: 'Number of Users Assigned',
        field: 'numberOfUsers',
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.date('createdAt'),
        colId: 'createdAt',
      },
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.date('updatedAt'),
        minWidth: 220,
        colId: 'updatedAt',
      },
      getActionColumn(actions),
    ]
  }, [actions])

  const csvFields = [
    {
      label: 'Pathway Name',
      value: 'name',
    },
    {
      label: 'Pathway Type',
      value: 'trainingPathwayTypeName',
    },
    {
      label: 'New Records',
      value: (data: Pathway) => {
        const count = data.newTimelinePostCount
        return count === -1 ? 'All' : count.toString()
      },
    },
    {
      label: 'Overall Records',
      value: 'overallRecords',
    },
    {
      label: 'Number of Users Assigned',
      value: 'numberOfUsers',
    },
    {
      label: 'Creation Date',
      value: (data: Pathway) => formatDate(data.createdAt, 'YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'Last Edited',
      value: (data: Pathway) => formatDate(data.updatedAt, 'YYYY-MM-DD HH:mm:ss'),
    },
  ]

  const { csvLoading, onExportCSV } = useExportCSV(
    getPathways,
    gridApi,
    columnApi,
    csvFields,
    'Pathways.csv',
  )

  return (
    <>
      <TableToolbar
        title="Pathways Overview"
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />
    </>
  )
}
