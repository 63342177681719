import {
  MetricRequestCareArticleEngagement,
  MetricRequestLearnArticleEngagement,
  MetricRequestLessonEngagement,
  MetricRequestLessonViewCount,
  MetricRequestQuestionnaireEngagement,
  MetricRequestTotalEngagement,
} from 'types'
import { TOKEN } from 'utils'
import { getBaseURL, httpHeaders } from './axios'

const prepareHeaders = () => {
  const token = localStorage.getItem(TOKEN)
  const headers: HeadersInit = {
    ...httpHeaders,
    authorization: `Bearer ${token}`,
  }
  return headers
}

export const addLessonViewMetric = async (request: MetricRequestLessonViewCount) => {
  fetch(getBaseURL() + '/metrics/lesson-view', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addLessonEngagementMetric = (request: MetricRequestLessonEngagement) => {
  return fetch(getBaseURL() + '/metrics/lesson-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addLearnArticleEngagementMetric = (request: MetricRequestLearnArticleEngagement) => {
  return fetch(getBaseURL() + '/metrics/learn-article-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addCareArticleEngagementMetric = (request: MetricRequestCareArticleEngagement) => {
  return fetch(getBaseURL() + '/metrics/care-article-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addQuestionnaireEngagementMetric = (request: MetricRequestQuestionnaireEngagement) => {
  return fetch(getBaseURL() + '/metrics/questionnaire-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addTotalEngagementMetric = (request: MetricRequestTotalEngagement) => {
  fetch(getBaseURL() + '/metrics/total-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}
