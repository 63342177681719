import { Box, Button } from '@mui/material'
import { addLessonEngagementMetric } from 'api/metrics'
import { getQuizWithResultsById } from 'api/quiz'
import { useAuthContext } from 'components/context/AuthProvider'
import { minButtonWidth } from 'const'
import { noop } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LessonAnsweredQuestion, IMetricScope, IMetricType, QuizResultData, Quiz } from 'types'
import { formatMetricDate } from 'utils'
import QuestionScreen from '../../question-screen'
import QuestionnaireFooter from '../../QuestionnaireFooter'
import useLearnMore from '../../use-learn-more'
import { QuizMetric } from '../QuizMetric'
import FinalScreen from '../summary'

interface Props {
  quizId: number
  completedLessonId: number
  handleClose: () => void
  onLoadingStart: () => void
  onLoadingFail: () => void
  onLoadingFinish: () => void
  retakeQuiz: () => void
}

const FIRST_SCREEN = -1

const ModalContent = (props: Props) => {
  const { handleClose } = props

  const { user } = useAuthContext()
  const [stage, setStage] = useState(FIRST_SCREEN)
  const [quiz, setQuiz] = useState<Quiz>()
  const [results, setResults] = useState<QuizResultData>()
  const [answers, setAnswers] = useState<Record<string, LessonAnsweredQuestion>>({})

  const percentCorrect = useMemo(() => {
    if (!results) {
      return 0
    }

    const percent = (results.correctAnswerCount * 100) / results.answeredQuestions.length
    return Math.round(percent)
  }, [results])

  const [openLearnMoreArticle] = useLearnMore(quiz?.lesson?.learnArticleId ?? 0)

  useEffect(() => {
    props.onLoadingStart()
    getQuizWithResultsById({ id: props.completedLessonId })
      .then(
        (response) => {
          const { quiz, results } = response.data
          setQuiz(quiz)
          if (results) {
            setResults(results)
            const answers = results.answeredQuestions.reduce((acc, item) => {
              acc[item.questionId] = item
              return acc
            }, {} as Record<string, LessonAnsweredQuestion>)

            setAnswers(answers)
          }

          props.onLoadingFinish()
        },
        () => props.onLoadingFail(),
      )
      .catch(() => props.onLoadingFail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const learnMoreClicked = useCallback(async () => {
    await openLearnMoreArticle()
    handleClose()
  }, [openLearnMoreArticle, handleClose])

  const question = useMemo(() => {
    const index = Math.floor(stage)
    return quiz?.questions[index]
  }, [quiz, stage])

  const answer = useMemo(() => {
    if (question) {
      return answers[question?.id]
    }
  }, [answers, question])

  const stageType = useMemo(() => {
    if (stage == FIRST_SCREEN) {
      return 'summary'
    }

    return 'question'
  }, [stage])

  const onClickNext = () => {
    setStage(stage + 1)
  }

  const onClickPrevious = () => {
    setStage(stage - 1)
  }

  const handleSaveAudioMetric = async (value: number, uuid: string, scope: IMetricScope) => {
    if (!user || !quiz) {
      return
    }

    await addLessonEngagementMetric({
      lessonUuid: uuid,
      lessonId: quiz.lessonId,
      courseId: quiz.lesson.courseId,
      userId: user.id,
      engagedFrom: formatMetricDate(value),
      engagedTo: formatMetricDate(),
      scope,
    })
  }

  const isPreviousVisible = stage !== FIRST_SCREEN
  const isNextVisible = stage < (quiz?.questions.length ?? 0) - 1

  if (!quiz) {
    return null
  }

  const renderStage = () => {
    if (stageType == 'summary')
      return (
        <FinalScreen
          retakeQuiz={props.retakeQuiz}
          handleClose={handleClose}
          name={quiz.name}
          learnMoreAvailable={!!quiz.lesson?.learnArticleId}
          onLearnMoreClicked={learnMoreClicked}
          percentCorrect={percentCorrect}
        />
      )

    if (!question) return null

    return (
      <QuestionScreen
        answer={answer?.answer}
        onChange={noop}
        metricType={IMetricType.lesson}
        metricEntityId={quiz.lessonId}
        onSaveAudioMetric={handleSaveAudioMetric}
        handleClose={handleClose}
        question={question}
        disabled={true}
      />
    )
  }

  return (
    <>
      <Box sx={{ mb: 4, flex: 1 }}>{renderStage()}</Box>
      <QuizMetric quiz={quiz} />

      <Box>
        <QuestionnaireFooter
          leftButton={
            <Button variant="outlined" onClick={onClickPrevious} sx={minButtonWidth}>
              {'< Previous'}
            </Button>
          }
          rightButton={
            <Button variant="outlined" onClick={onClickNext} sx={minButtonWidth}>
              {'Next >'}
            </Button>
          }
          isPreviousVisible={isPreviousVisible}
          isNextVisible={isNextVisible}
        />
      </Box>
    </>
  )
}

export default ModalContent
