export enum BackendGender {
  male = 'male',
  female = 'female',
  selfDescribe = 'preferToSelfDescribe',
  notAnswer = 'preferNotToAnswer',
}

export type Profile = {
  firstName: string
  lastName: string
  street: string
  zipCode: string
  state: string
  country: string
  city: string
  phoneNumber: string
  dateOfBirth: string
  gender: BackendGender
  email: string
}

export type ProfileUpdateRequest = Profile

export type TrainingDetails = {
  code: string
  effectiveDate: string
  expireDate: string
  education: string
  experience: string
  language: string
  other: string
}
