import { Grid } from '@mui/material'
import { getTrainingDetails } from 'api/profile'
import { educationOptions, preferredLanguageOptions, yearsOfExperience } from 'models/user.model'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import FormInput from '../../../form-elements/FormInput'
import FormSelect from '../../../form-elements/FormSelect'
import ModalFailPlaceholder from '../../../placeholders/ModalFailPlaceholder'
import Loader from '../../../placeholders/ModalLoadingPlaceholder'

type FormValues = {
  code: string
  effectiveDate: string
  expireDate: string
  education: string
  experience: string
  language: string
  other: string
}

type Props = {
  onClose: () => void
}

const ProfileTrainingDetailsForm = ({ onClose }: Props) => {
  const [form, setForm] = useState<FormValues>()

  const query = useQuery('getTrainingDetails', () => getTrainingDetails(), {
    onSuccess: (data) => {
      setForm(data)
    },
  })

  if (query.isLoading) {
    return <Loader show />
  }

  if (query.isError)
    return (
      <ModalFailPlaceholder
        show={true}
        py={7}
        error="Can't load profile data"
        handleClose={onClose}
      />
    )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return <TrainingDetails {...form!} />
}

const TrainingDetails = (props: FormValues) => {
  const formMethods = useForm({ defaultValues: props })

  return (
    <FormProvider {...formMethods}>
      <FormInput label="Institutional access code" name="code" disabled fullWidth sx={{ mb: 1 }} />

      <Grid container columnSpacing={2}>
        <Grid item sm={6} xs={12}>
          <FormInput
            label="Effective date"
            fullWidth
            name="effectiveDate"
            sx={{ mb: 1 }}
            disabled
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormInput label="Expire date" fullWidth name="expireDate" sx={{ mb: 1 }} disabled />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormSelect
            label="How much education did you complete?"
            fullWidth
            name="education"
            sx={{ mb: 1 }}
            options={educationOptions}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <FormSelect
            label="Years of experience as a professional caregiver"
            fullWidth
            name="experience"
            options={yearsOfExperience}
            disabled
            sx={{ mb: 1 }}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item sm={6} xs={12}>
          <FormSelect
            label="What is your preferred language?"
            fullWidth
            name="language"
            sx={{ mb: 1 }}
            options={preferredLanguageOptions}
            disabled
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormInput label="Other" fullWidth name="languageOther" disabled sx={{ mb: 1 }} />
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ProfileTrainingDetailsForm
