import { getQuestionsByName, getQuestionsForQuestionnaire } from 'api/question'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { QuestioningType } from 'types/timeline.types'
import { selectOptionsFromTableResponse } from 'utils'
import { Option } from '../../form-elements/FormSelect'
import ModalWithSelect from '../simple-modals/ModalWithSelect'

interface Props {
  entityId: string
  type: QuestioningType
  isOpen: boolean
  handleClose: () => void
  onAdd: (question: Option) => void
}

const AddQuestionToQuizModal = (props: Props) => {
  const { entityId, type, isOpen, handleClose, onAdd } = props

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (isOpen) {
      setSearch('')
    }
  }, [isOpen])

  const questionsQuery = useQuery(
    ['getQuestionsByName', { search }],
    () => {
      if (type === 'questionnaire') {
        return getQuestionsForQuestionnaire(entityId, search, type)
      }
      return getQuestionsByName(search, type)
    },
    {
      enabled: isOpen,
      select: selectOptionsFromTableResponse,
    },
  )

  const onSubmit = (id: number) => {
    const entity = questionsQuery?.data?.find((it) => it.value === id)
    if (entity) {
      onAdd(entity)
      handleClose()
    }
  }

  return (
    <ModalWithSelect
      title="Add Question"
      label="Question"
      buttonText="Add"
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={onSubmit}
      options={questionsQuery.data ?? []}
      loading={false}
      loadingOptions={questionsQuery.isLoading}
      onChangeInput={setSearch}
    />
  )
}

export default React.memo(AddQuestionToQuizModal)
