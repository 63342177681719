import ScrollContainer from 'components/page/ScrollContainer'
import { heightOfToolbar } from 'const'
import { UICollapseProvider } from 'features/UI'
import intersection from 'lodash/intersection'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppSelector } from 'store/hooks'
import { selectTagsFiltersIds } from 'store/slice/tagsFilters.slice'
import { selectTimelineFilter, selectTimelineFiltersTypes } from 'store/slice/timelineFilters.slice'
import {
  ArticleType,
  CourseTimelineItem,
  ETimelinePostType,
  Nullish,
  Tag,
  TimelineFilter,
  TimelineFilterStatusMap,
  TimelineItem,
} from 'types'
import { TimelineArticleItem } from '../TimelineArticleItem/TimelineArticleItem'
import { TimelineCourseItem } from '../TimelineCourseItem/TimelineCourseItem'
import { TimelineDateItem } from '../TimelineDateItem/TimelineDateItem'
import { TimelineExamItem } from '../TimelineExamItem/TimelineExamItem'
import { TimelineLessonItem } from '../TimelineLessonItem/TimelineLessonItem'
import { TimelineNoteItem } from '../TimelineNoteItem/TimelineNoteItem'
import { TimelineQuestioningItem } from '../TimelineQuestioningItem/TimelineQuestioningItem'

const hasTags = (tags: Tag[], tagsIds: number[]) => {
  if (!tags) {
    return false
  }

  const itemTagsIds = tags.map((tag) => tag.id)
  return intersection(itemTagsIds, tagsIds).length > 0
}

const filterElements =
  (
    showTypes: Nullish<ETimelinePostType[]>,
    timelineFilter: Nullish<TimelineFilter[]>,
    tagsIds: Nullish<number[]>,
  ) =>
  (item: TimelineItem) => {
    let isShowByType = true
    if (showTypes !== null) {
      isShowByType = showTypes.includes(item.type)
      if (showTypes.includes(ETimelinePostType.course)) {
        isShowByType = isShowByType || !!item.course
      }
    }

    let isShowByFilter = true
    if (timelineFilter) {
      const status = timelineFilter.find((filterValue) => {
        const value = TimelineFilterStatusMap[filterValue]
        return item.status === value
      })

      isShowByFilter = status !== undefined
    }

    const displayFilter = isShowByType && isShowByFilter
    let tagsFilter = true

    if (tagsIds !== null) {
      if (item.type === ETimelinePostType.lessons) {
        tagsFilter = hasTags(item.lesson.tags, tagsIds)
      } else if (item.type === ETimelinePostType.careArticle) {
        tagsFilter = hasTags(item.careArticle.tags, tagsIds)
      }
    }

    return displayFilter && tagsFilter
  }

export function TimelineList({ timeline }: { timeline: TimelineItem[] }) {
  const userCreatedAt = useAppSelector((state) => state.currentUser.createdAt)
  const timelineTypes = useSelector(selectTimelineFiltersTypes)
  const timelineFilter = useSelector(selectTimelineFilter)
  const tagsIds = useSelector(selectTagsFiltersIds)

  const timelineElements = useMemo(() => {
    if (!timeline) {
      return []
    }

    const filteredItems =
      timeline.filter(filterElements(timelineTypes, timelineFilter, tagsIds)) || []

    // group lessons and exams by course
    const groupedItems: (TimelineItem | CourseTimelineItem)[] = []
    const coursesIndexes: { [key: number]: number } = {}

    filteredItems.forEach((item) => {
      if (
        (item.type === ETimelinePostType.lessons ||
          item.type === ETimelinePostType.questionnaire) &&
        item.course
      ) {
        const courseId = item.course.id
        if (coursesIndexes[courseId] === undefined) {
          coursesIndexes[courseId] = groupedItems.length
          groupedItems.push({
            id: courseId,
            type: 'course',
            name: item.course.name,
            status: item.course.status,
            bannerUrl: item.course.bannerUrl,
            items: [],
          })
        }

        const course = groupedItems[coursesIndexes[courseId]] as CourseTimelineItem
        course.items.push(item)
        return
      }

      groupedItems.push(item)
    })

    return groupedItems
  }, [tagsIds, timeline, timelineFilter, timelineTypes])

  return (
    <ScrollContainer deltaSpace={heightOfToolbar} fixLeft>
      <UICollapseProvider>
        <TimelineDateItem date={new Date().toISOString()} text="Today" />

        {timelineElements.map((item) => {
          const isCourse = item.type === 'course'

          if (!isCourse) {
            if (item.type === ETimelinePostType.note) {
              return (
                <TimelineNoteItem
                  key={`${item.type}_${item.id}`}
                  timelineId={item.id}
                  id={item.noteId}
                  title={item.note.subject}
                  text={item.note.body}
                  date={item.note.createdAt}
                  authorId={item.note.authorId}
                  authorName={item.note.authorName}
                  status={item.status}
                />
              )
            }
            if (item.type === ETimelinePostType.careArticle) {
              return (
                <TimelineArticleItem
                  key={`${item.type}_${item.id}`}
                  id={item.careArticleId}
                  title={item.careArticle.post || item.careArticle.name}
                  type={ArticleType.CareArticle}
                  timelineId={item.id}
                  status={item.status}
                  bannerUrl={item.careArticle.bannerUrl}
                />
              )
            }
            if (item.type === ETimelinePostType.queries) {
              return (
                <TimelineQuestioningItem
                  key={`${item.type}_${item.id}`}
                  title={item.query.name}
                  date={item.query.createdAt}
                  id={item.queryId}
                  type="query"
                  status={item.status}
                />
              )
            }
            return null
          }

          return (
            <TimelineCourseItem
              key={item.id}
              id={item.id}
              name={item.name}
              status={item.status}
              bannerUrl={item.bannerUrl}
            >
              {item.items.map((itemInCourse) => {
                if (itemInCourse.type === ETimelinePostType.lessons) {
                  return (
                    <TimelineLessonItem
                      key={`${itemInCourse.type}_${itemInCourse.id}`}
                      item={itemInCourse}
                    />
                  )
                }

                if (itemInCourse.type === ETimelinePostType.questionnaire) {
                  return (
                    <TimelineExamItem
                      key={`${itemInCourse.type}_${itemInCourse.id}`}
                      item={itemInCourse}
                    />
                  )
                }

                return null
              })}
            </TimelineCourseItem>
          )
        })}

        <TimelineDateItem date={userCreatedAt || ''} text="Start using app" />
      </UICollapseProvider>
    </ScrollContainer>
  )
}
