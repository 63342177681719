import { Box, Stack } from '@mui/material'
import { getCourseById, updateCourse } from 'api/courses'
import Breadcrumbs from 'components/Breadcumbs'
import PageTitle from 'components/page/PageTitle'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { ExamControlAdd, ExamPreviewPanel } from 'features/Exam'
import { LessonControlAdd, LessonsGrid } from 'features/Lesson'
import { MetricText } from 'features/Metric/components/MetricText/MetricText'
import { UIControlChangeNameAndIcon } from 'features/UI'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import React, { useMemo } from 'react'
import { routes } from 'routes/routes'
import { Course } from 'types'
import { isDefined, useSearchParamsObject } from 'utils'

export const CourseEditPage = () => {
  const params = useSearchParamsObject()

  const courseId = useMemo(() => {
    const value = parseInt(params.id as string)
    if (!isNaN(value)) {
      return value
    }

    return 0
  }, [params])

  const { data, setData, loading, setLoading, reloadData } = useEntityDataControl({
    id: courseId,
    loadFunc: getCourseById,
    warning: "Can't load course data",
  })

  const courseName = data?.name || ''

  const path = useMemo(() => {
    return [
      {
        href: routes.courses,
        text: 'Courses',
      },
      {
        text: courseName,
      },
    ]
  }, [courseName])

  const questionnaireId = useMemo(() => {
    if (!data) return null
    if (data.questionnaireId === undefined || data.questionnaireId === null) return null

    return data.questionnaireId
  }, [data])

  if (loading) {
    return <LoadingPlaceholder />
  }

  return (
    <>
      <Stack spacing={2} flex={1}>
        <PageTitle>Course View</PageTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs path={path} />

            <UIControlChangeNameAndIcon<Course>
              entityName="Сourse"
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
              updateRequest={updateCourse}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            {!isDefined(data?.questionnaireId) && <ExamControlAdd parentId={courseId} />}

            <LessonControlAdd courseId={courseId} />
          </Stack>
        </Stack>

        {data && (
          <MetricText
            wordCount={data.wordCount}
            talkTime={data.talkTime}
            silentReadingTime={data.silentReadingTime}
            fleschReadingEase={data.fleschReadingEase}
            fryReadabilityGradeLevel={data.fryReadabilityGradeLevel}
            inArticle={false}
          />
        )}

        <LessonsGrid courseId={courseId} lessonsData={data?.lessons} />

        {questionnaireId && (
          <Box pb={2}>
            <ExamPreviewPanel
              type="questionnaire"
              id={questionnaireId}
              name={data?.questionnaire?.name}
              onReload={reloadData}
            />
          </Box>
        )}
      </Stack>
    </>
  )
}
