import emotionStyled from '@emotion/styled'
import IconLearnArticle from 'images/icons/1_man_with_board.png'
import IconArticle from 'images/icons/icon_article.png'
import IconJournal from 'images/icons/icon_journal.png'

interface Props {
  isOnTimeline?: boolean
}

export const UIItemImage = emotionStyled('img')<Props>(({ isOnTimeline }) => {
  const baseProps = `
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
  `

  const timelineProps = `
    position: absolute;
    top: 28px;
    left: 28px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 1;
  `

  if (isOnTimeline) {
    return `
      ${baseProps}
      ${timelineProps}
    `
  }

  return baseProps
})

export const UIPencilImg = (props: Props) => {
  return <UIItemImage src={IconJournal} isOnTimeline={props.isOnTimeline} />
}

export const UICarePostArticleImg = (props: Props) => {
  return <UIItemImage src={IconArticle} isOnTimeline={props.isOnTimeline} />
}

export const UILearnArticleImg = (props: Props) => {
  return <UIItemImage src={IconLearnArticle} isOnTimeline={props.isOnTimeline} />
}
