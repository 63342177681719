import { Button, Stack } from '@mui/material'
import {
  createLibraryArticle,
  deleteLibraryArticle,
  getLibraryArticleById,
  updateLibraryArticle,
} from 'api/learnArticles'
import {
  getLibraryTopicById,
  updateLibraryTopic,
  updateOrderOfTopicArticles,
} from 'api/learnTopics'
import Breadcrumbs from 'components/Breadcumbs'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import PageTitle from 'components/page/PageTitle'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { MetricText } from 'features/Metric/components/MetricText/MetricText'
import { UIControlChangeNameAndIcon } from 'features/UI'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import {
  PublishAction,
  publishSuccessCallbackTopic,
  usePublishModalControl,
} from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { LearnArticle, LearnTopic } from 'types'
import { useSearchParamsObject } from 'utils'
import { LearnLibraryTopicGrid } from '../LearnLibraryTopicGrid/LearnLibraryTopicGrid'

const useOnSaveArticle = (topicId: number) => {
  const navigate = useNavigate()
  const [isOpenAddArticleModal, openAddArticle, closeAddArticleModal] = useShowControl()

  const [addLoading, setAddLoading] = useState(false)

  const onSaveArticle = async (name: string) => {
    try {
      setAddLoading(true)
      const response = await createLibraryArticle({
        topicId,
        name: name,
      })
      if (response.data.id) {
        const id = response.data.id
        navigate(`${routes.learnArticleEdit}?id=${id}`)
      }
    } finally {
      setAddLoading(false)
    }
  }

  return { onSaveArticle, openAddArticle, isOpenAddArticleModal, addLoading, closeAddArticleModal }
}

const useDataControl = (id: string | undefined) => {
  const { data, setData, loading, setLoading, reloadData } = useEntityDataControl({
    id,
    loadFunc: getLibraryTopicById,
    warning: "Can't load topic data",
  })

  const [articles, setArticles] = useState(data?.articles || [])

  useEffect(() => {
    setArticles(data?.articles || [])
  }, [data?.articles])

  return {
    data,
    setData,
    loading,
    setLoading,
    reloadData,
    articles,
    setArticles: setArticles,
  }
}

export function LearnLibraryTopicPage() {
  const params = useSearchParamsObject()
  const id = useMemo(() => {
    const value = parseInt(params.id as string)
    if (!isNaN(value)) {
      return value
    }

    return 0
  }, [params])
  const { data, setData, loading, setLoading, reloadData, articles, setArticles } = useDataControl(
    id.toString(),
  )

  const topicName = data?.name || ''
  const path = useMemo(() => {
    return [
      {
        href: routes.manageLearnLibrary,
        text: 'Learn Library',
      },
      {
        text: topicName,
      },
    ]
  }, [topicName])

  const { onSaveArticle, openAddArticle, isOpenAddArticleModal, closeAddArticleModal } =
    useOnSaveArticle(data?.id ?? -1)

  const { idToRemove, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteLibraryArticle,
      successCallback: reloadData,
      warning: "Can't remove article from topic",
    })

  const { action, idToPublish, closePublishModal, publishLoading, handleConfirmPublish } =
    usePublishModalControl({
      successCallback: (id: number, action: PublishAction) => {
        publishSuccessCallbackTopic(id, action, data, setData)
      },
      loadFunc: getLibraryArticleById,
      updateFunc: updateLibraryArticle,
    })

  const handleLessonDelete = useCallback(
    (id: number) => {
      setArticles(articles.filter((l) => l.id !== id))
    },
    [articles, setArticles],
  )

  const handlePublish = useCallback(
    (id: number, published: boolean) => {
      const article = articles.find((l) => l.id === id)
      if (!!article) {
        const updatedArticle: LearnArticle = { ...article, isPublished: published }
        setArticles(articles.map((l) => (l.id === id ? updatedArticle : l)))
      }
    },
    [articles, setArticles],
  )

  const updateOrderOfTopicArticlesMutation = useMutation(updateOrderOfTopicArticles)
  const handleReorder = useCallback(
    (ids: number[]) => {
      updateOrderOfTopicArticlesMutation.mutate(
        { id, articles: ids },
        {
          onSuccess: () => {
            const orderedArticles = [...articles].sort(
              (a, b) => ids.indexOf(a.id) - ids.indexOf(b.id),
            )
            setArticles(orderedArticles)
          },
          onError: () => {
            setArticles([...articles])
          },
        },
      )
    },
    [updateOrderOfTopicArticlesMutation, id, articles, setArticles],
  )

  if (loading) {
    return <LoadingPlaceholder />
  }

  return (
    <>
      <Stack spacing={2} flex={1}>
        <PageTitle>Topic View</PageTitle>

        <Stack direction="row" spacing={2} flex={1} justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs path={path} />

            <UIControlChangeNameAndIcon<LearnTopic>
              entityName="Topic"
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
              updateRequest={updateLibraryTopic}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={openAddArticle}>
              Add Article
            </Button>
          </Stack>
        </Stack>

        {data && (
          <MetricText
            wordCount={data.wordCount}
            talkTime={data.talkTime}
            silentReadingTime={data.silentReadingTime}
            fleschReadingEase={data.fleschReadingEase}
            fryReadabilityGradeLevel={data.fryReadabilityGradeLevel}
            inArticle={false}
          />
        )}

        <LearnLibraryTopicGrid
          articles={articles}
          onDelete={handleLessonDelete}
          onReorder={handleReorder}
          onPublish={handlePublish}
        />
      </Stack>

      <ModalWithTextInput
        title="Create Learn Library Article"
        label="Article Name"
        buttonText="Create"
        isOpen={isOpenAddArticleModal}
        handleClose={closeAddArticleModal}
        onSubmit={onSaveArticle}
        loading={loading}
      />

      <ConfirmRemoveModal
        entityToRemove="Article"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Learn Library Article"
      />
    </>
  )
}
