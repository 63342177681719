import { useCallback, useState } from 'react'
import { ArticleType } from 'types'

type OutProps = [
  boolean,
  number | null,
  number | null,
  ArticleType,
  (id: number, timelineId: number | null, type: ArticleType) => void,
  () => void,
]

export const useShowControlArticle = (): OutProps => {
  const [id, setId] = useState<number | null>(null)
  const [timelineId, setTimelineId] = useState<number | null>(null)
  const [type, setType] = useState<ArticleType>(ArticleType.CareArticle)
  const [isOpen, setOpen] = useState(false)

  const onOpen = useCallback((id: number, timelineId: number | null, newType: ArticleType) => {
    setId(id)
    setTimelineId(timelineId)
    setType(newType)
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setId(null)
    setTimelineId(null)
    setOpen(false)
  }, [])

  return [isOpen, id, timelineId, type, onOpen, onClose]
}
