import {
  BaseTableRequest,
  RequestById,
  Response,
  TableResponse,
  TimelineItem,
  TimelineSearchResult,
  UpdateTimelineItem,
  User,
} from 'types'
import { getFromLocalStorage, USER_DATA } from 'utils'
import axiosInstance from './axios'

export const getTimelines = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<TimelineItem>>('/timelines', request)
}

export const getTimelineById = async (request: RequestById): Promise<Response<TimelineItem>> => {
  return axiosInstance.post('/timelines/read', request)
}

export const setStatus = async (
  request: UpdateTimelineItem,
): Promise<Response<{ timeline: TimelineItem; status: 'created' | 'updated' }>> => {
  return axiosInstance.post('/timelines/status', request)
}

export const searchPostTimeline = async (
  searchText: string,
): Promise<TableResponse<TimelineSearchResult>> => {
  const user = getFromLocalStorage(USER_DATA) as User | null
  return axiosInstance.post('/timelines/search', {
    params: {
      searchText,
      userId: user?.id,
    },
    filter: null,
    sort: null,
    pagination: {
      page: 1,
      pageSize: 25,
    },
  })
}
