import { Box, Stack, Tab, Tabs } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { getCohorts } from 'api/cohorts'
import {
  getActiveEngagementPerUserReport,
  getEngagementPerSessionReport,
  getTotalEngagementPerUserReport,
} from 'api/reports'
import columnRenderers from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { createCohortsColumn, createCohortsCsvColumn } from 'features/Cohorts'
import { getActionColumn, RowAction, UIFilterAsyncAutocomplete } from 'features/UI'
import { CSVField, useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import {
  BaseTableRequest,
  EngagementPerSession,
  EngagementReportTypes,
  FilterModel,
  FilterModelNumberArray,
  Nullish,
  Option,
} from 'types'
import { formatDate, getRoute, useGetDataSource } from 'utils'
import { createEngagementColumn, createEngagementCsvColumn } from '../../helpers/engagementColumn'
import { prepareDateRangeParams } from '../../helpers/prepareDateRangeParams'
import { useDateRange } from '../../hooks/useDateRange'
import { DateRangeToolbar } from '../DateRangeToolbar/DateRangeToolbar'

const activeColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'User First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'User Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  { headerName: 'Access Code', field: 'userAccessCode', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  { headerName: 'Lesson Name', field: 'lessonName', filter: 'agTextColumnFilter' },
  { headerName: 'Course Name', field: 'courseName', filter: 'agTextColumnFilter' },
  createEngagementColumn('Active Engagement', 'activeEngagement'),
]

const totalColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'User First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'User Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  { headerName: 'Access Code', field: 'userAccessCode', filter: 'agTextColumnFilter' },
  {
    headerName: 'First Login',
    field: 'userFirstLoggedInAt',
    cellRenderer: columnRenderers.date('userFirstLoggedInAt', 'YYYY-MM-DD HH:mm:ss'),
  },
  {
    headerName: 'Last Login',
    field: 'userLastLoggedInAt',
    cellRenderer: columnRenderers.date('userLastLoggedInAt', 'YYYY-MM-DD HH:mm:ss'),
  },
  {
    headerName: 'Total Numbers of Login',
    field: 'userTotalNumbersOfLogin',
  },
  createEngagementColumn('Learn Articles', 'learnArticleTime'),
  createEngagementColumn('Lesson', 'lessonTime'),
  createEngagementColumn('Quizzes', 'quizTime'),
  createEngagementColumn('TimeLine Posts', 'careArticleTime'),
  createEngagementColumn('Query', 'queryTime'),
  createEngagementColumn('Exams', 'questionnaireTime'),
  createEngagementColumn('Lessons Audio Time', 'lessonAudioTime'),
  createEngagementColumn('Learn Articles Audio Time', 'learnArticleAudioTime', { minWidth: 250 }),
  createEngagementColumn('Timeline Posts Audio Time', 'careArticleAudioTime', { minWidth: 250 }),
  createEngagementColumn('Total Time Online', 'totalEngagement'),
]

const sessionColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  { headerName: 'Access Code', field: 'userAccessCode', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Login Date',
    field: 'startedAt',
    cellRenderer: columnRenderers.date('startedAt', 'YYYY-MM-DD HH:mm:ss'),
    minWidth: 200,
  },
  createEngagementColumn('Total Login Time', 'totalTime'),
]

const csvFieldsActive: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  {
    label: 'User First Name',
    value: 'userFirstName',
  },
  {
    label: 'User Last Name',
    value: 'userLastName',
  },
  {
    label: 'Login',
    value: 'userEmail',
  },
  {
    label: 'Access Code',
    value: 'userAccessCode',
  },
  createCohortsCsvColumn('userCohorts'),
  {
    label: 'Lesson Name',
    value: 'lessonName',
  },
  {
    label: 'Course Name',
    value: 'courseName',
  },
  createEngagementCsvColumn('Active Engagement', 'activeEngagement'),
]

const csvFieldsTotal: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  {
    label: 'User First Name',
    value: 'userFirstName',
  },
  {
    label: 'User Last name',
    value: 'userLastName',
  },
  {
    label: 'Login',
    value: 'userEmail',
  },
  {
    label: 'AccessCode',
    value: 'userAccessCode',
  },
  createCohortsCsvColumn('userCohorts'),
  createEngagementCsvColumn('Learn Articles', 'learnArticleTime'),
  createEngagementCsvColumn('Lesson', 'lessonTime'),
  createEngagementCsvColumn('Quizzes', 'quizTime'),
  createEngagementCsvColumn('TimeLine Posts', 'careArticleTime'),
  createEngagementCsvColumn('Query', 'queryTime'),
  createEngagementCsvColumn('Exams', 'questionnaireTime'),
  createEngagementCsvColumn('Lessons Audio Time', 'lessonAudioTime'),
  createEngagementCsvColumn('Learn Articles Audio Time', 'learnArticleAudioTime'),
  createEngagementCsvColumn('Timeline Posts Audio Time', 'careArticleAudioTime'),
  createEngagementCsvColumn('Total Time Online', 'totalEngagement'),
]

const csvFieldsSession: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  { label: 'Access Code', value: 'userAccessCode' },
  createCohortsCsvColumn('userCohorts'),
  {
    label: 'Login Date',
    value: (data: EngagementPerSession) => formatDate(data.startedAt, 'YYYY-MM-DD HH:mm:ss'),
  },
  createEngagementCsvColumn('Total Time Online', 'totalTime'),
]

type IReportsActions = {
  [key in EngagementReportTypes]: Nullish<RowAction[]>
}

type IReportsTabOptions = {
  [key in EngagementReportTypes]: {
    columns: ColDef[]
    title: string
    csvName: string
    csvFields: CSVField[]
    hasDateFilter: boolean
  }
}

const ReportsTabOptions: IReportsTabOptions = {
  [EngagementReportTypes.Course]: {
    title: 'Course Engagement per User',
    columns: activeColumnDefs,
    csvName: 'User Active Engagement Report.csv',
    csvFields: csvFieldsActive,
    hasDateFilter: false,
  },
  [EngagementReportTypes.Total]: {
    title: 'Total Engagement Per User',
    columns: totalColumnDefs,
    csvName: 'User Total Engagement Report.csv',
    csvFields: csvFieldsTotal,
    hasDateFilter: true,
  },
  [EngagementReportTypes.Session]: {
    title: 'Login Report',
    columns: sessionColumnDefs,
    csvName: 'Login Report.csv',
    csvFields: csvFieldsSession,
    hasDateFilter: true,
  },
}

const ReportOrder = [
  EngagementReportTypes.Course,
  EngagementReportTypes.Total,
  EngagementReportTypes.Session,
]

export const ReportsEngagementUser = () => {
  const navigate = useNavigate()
  const { engagementType } = useParams()

  const selectedTab = useMemo(() => {
    if (!engagementType) return EngagementReportTypes.Course

    return engagementType as EngagementReportTypes
  }, [engagementType])

  const tabOptions = useMemo(() => ReportsTabOptions[selectedTab], [selectedTab])

  const handleChangeSelectedTab = useCallback(
    (_: React.SyntheticEvent, newValue: EngagementReportTypes) => {
      const route = getRoute(routes.reports_engagement_user, { engagementType: newValue })
      navigate(route)
    },
    [navigate],
  )

  const [cohortsFilterValue, setCohortsFilterValue] = useState<Option[]>([])

  const handleDetailsClick = useCallback(
    (sessionUuid: number) => {
      const route = getRoute(routes.reports_engagement_user_session, { sessionUuid })
      navigate(route)
    },
    [navigate],
  )

  const actions: IReportsActions = useMemo(() => {
    return {
      [EngagementReportTypes.Course]: null,
      [EngagementReportTypes.Total]: null,
      [EngagementReportTypes.Session]: [{ name: 'See details', onClick: handleDetailsClick }],
    }
  }, [handleDetailsClick])

  const columns = useMemo(() => {
    const tabActions = actions[selectedTab]
    if (!tabActions) {
      return tabOptions.columns
    }

    return [...tabOptions.columns, getActionColumn(tabActions, 'sessionUuid')]
  }, [actions, selectedTab, tabOptions.columns])

  const {
    rangeType,
    year,
    month,
    selectedDate,
    dateInterval,
    onChangeDateInterval,
    onChangeRange,
    onChangeYear,
    onChangeMonth,
    setSelectedDate,
  } = useDateRange()

  const getActiveEngagementWrapper = useCallback(
    (req: BaseTableRequest) => {
      const filter: FilterModel = {
        ...(req.filter || {}),
      }

      if (cohortsFilterValue.length > 0) {
        filter.cohortId = {
          type: 'number',
          op: 'in',
          value: cohortsFilterValue.map((cohort) => cohort.value),
        } as FilterModelNumberArray
      }

      const request = { ...req, filter }

      const dateParams = prepareDateRangeParams(rangeType, year, month, selectedDate, dateInterval)

      if (selectedTab === EngagementReportTypes.Course) {
        return getActiveEngagementPerUserReport(request)
      }

      if (selectedTab === EngagementReportTypes.Total) {
        return getTotalEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.Session) {
        return getEngagementPerSessionReport({ ...request, params: dateParams })
      }

      return getTotalEngagementPerUserReport({ ...request, params: dateParams })
    },
    [cohortsFilterValue, dateInterval, month, rangeType, selectedDate, selectedTab, year],
  )

  const getDataSourceActive = useGetDataSource(getActiveEngagementWrapper)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSourceActive)

  const { csvLoading, onExportCSV } = useExportCSV(
    getActiveEngagementWrapper,
    gridApi,
    columnApi,
    tabOptions.csvFields,
    tabOptions.csvName,
  )

  useRefreshTable({ params: { rangeType, year, month, selectedDate }, gridApi })

  const handleChangeCohortFilter = useCallback(
    (newValue: Option[]) => {
      setCohortsFilterValue(newValue)
      gridApi?.refreshServerSide()
    },
    [gridApi],
  )

  return (
    <>
      <TableToolbar
        left={
          <Tabs value={engagementType} onChange={handleChangeSelectedTab} indicatorColor="primary">
            {ReportOrder.map((type) => (
              <Tab key={type} value={type} label={getTitle(ReportsTabOptions[type].title)} />
            ))}
          </Tabs>
        }
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />

      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        {tabOptions.hasDateFilter && (
          <DateRangeToolbar
            rangeType={rangeType}
            year={year}
            month={month}
            selectedDate={selectedDate}
            dateInterval={dateInterval}
            onChangeDateInterval={onChangeDateInterval}
            onChangeRange={onChangeRange}
            onChangeYear={onChangeYear}
            onChangeMonth={onChangeMonth}
            setSelectedDate={setSelectedDate}
          />
        )}

        <Box minWidth="200px">
          <UIFilterAsyncAutocomplete
            label="Cohorts"
            value={cohortsFilterValue}
            onChange={handleChangeCohortFilter}
            queryId="cohorts"
            apiRequest={getCohorts}
          />
        </Box>
      </Stack>

      <InfinityAgGrid columnDefs={columns} pagination onGridReady={onGridReady} />
    </>
  )
}
