import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, FormLabel, Stack } from '@mui/material'
import { createMediaFile } from 'api/mediaFiles'
import { getByIdQuestion, updateQuestion } from 'api/question'
import Breadcrumbs from 'components/Breadcumbs'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import UploadAudioModal from 'components/modals/UploadAudioModal'
import NotificationSys from 'components/NotificationSystem'
import PageTitle from 'components/page/PageTitle'
import { FIELD_IS_REQUIRED } from 'const'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import { useUploadAudioModal } from 'hooks/useUploadAudioModal'
import capitalize from 'lodash/capitalize'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { routes } from 'routes/routes'
import { EQuestionType, Nullish, Question } from 'types'
import { isDefined } from 'utils'
import { object, string, TypeOf } from 'zod'
import { QuestionEditorFieldAnswersList } from '../QuestionEditorFieldAnswersList/QuestionEditorFieldAnswersList'
import { QuestionEditorFieldExplanation } from '../QuestionEditorFieldExplanation/QuestionEditorFieldExplanation'
import { QuestionEditorFieldInstruction } from '../QuestionEditorFieldInstruction/QuestionEditorFieldInstruction'
import { QuestionEditorFieldQuestion } from '../QuestionEditorFieldQuestion/QuestionEditorFieldQuestion'
import { QuestionEditorFieldStem } from '../QuestionEditorFieldStem/QuestionEditorFieldStem'

const MAX_LENGTH_ERROR_MESSAGE = (max_length: number) =>
  `Please enter a maximum of ${max_length} characters`

const MAX_LENGTH = {
  stem: 500,
  question: 250,
  instruction: 50,
  explanation: 700,
  option: 300,
}

const formSchema = (type: EQuestionType) =>
  object({
    stem: string().max(MAX_LENGTH.stem, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.stem)),
    question: string()
      .min(1, FIELD_IS_REQUIRED)
      .max(MAX_LENGTH.question, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.question)),
    instructions: string()
      .min(1, FIELD_IS_REQUIRED)
      .max(MAX_LENGTH.instruction, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.instruction)),
    explanation:
      type !== EQuestionType.query
        ? string()
            .min(1, FIELD_IS_REQUIRED)
            .max(MAX_LENGTH.explanation, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.explanation))
        : string(),
  })

export const QuestionEditor = ({
  editorType,
  data,
  onSubmitChangeName,
  onSaveQuestion,
  saving,
}: {
  editorType: 'add' | 'edit'
  data: Question
  onSubmitChangeName: (newName: string) => void
  onSaveQuestion: (question: Question) => void
  saving: boolean
}) => {
  const {
    name,
    stem,
    question,
    type,
    explanation,
    instructions,
    answers: initialAnswers,
    correctAnswer: initialCorrectAnswer,
    mediaFileId: initialMediaFileId,
  } = data

  const initialCorrectId = initialAnswers.findIndex((it) => it === initialCorrectAnswer)

  const [answers, setAnswers] = useState(!initialAnswers?.length ? [''] : initialAnswers)
  const [mediaFileId, setMediaFileId] = useState(initialMediaFileId)
  const [file, setFile] = useState<Nullish<File>>(null)
  const [correctId, setCorrectId] = useState<number>(initialCorrectId)
  const [isOpenEditQuestionName, openEditQuestionName, closeEditQuestionName] = useShowControl()

  const pageTitle = useMemo(() => {
    return `${capitalize(editorType)} Question View`
  }, [editorType])

  const handleSubmitChangeName = useCallback(
    (name: string) => {
      onSubmitChangeName(name)
      closeEditQuestionName()
    },
    [closeEditQuestionName, onSubmitChangeName],
  )

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema(type)),
      defaultValues: {
        stem,
        question,
        instructions,
        explanation,
      },
    }
  }, [explanation, question, type, stem, instructions])

  function testUnique(arr: string[]) {
    const n = arr.length
    for (let i = 0; i < n - 1; i++) {
      for (let j = i + 1; j < n; j++) {
        if (arr[i] === arr[j]) return false
      }
    }
    return true
  }

  type FormType = TypeOf<ReturnType<typeof formSchema>>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = async (values: FormType) => {
    if (answers.some((it) => it.trim() === '')) {
      return
    }

    if (answers.some((it) => it.length > MAX_LENGTH.option)) {
      return
    }

    if (!testUnique(answers)) {
      NotificationSys.showWarning('Some of answers are not unique. Please correct before saving')
      return
    }

    let newMediaFileId = mediaFileId
    if (file) {
      newMediaFileId = await onSubmitAudio(file)
      setMediaFileId(newMediaFileId)
    }

    const newEntity: Question = {
      id: data.id,
      name,
      type: data.type,
      stem: values.stem,
      question: values.question,
      instructions: values.instructions,
      explanation: values.explanation,
      correctAnswer: answers[correctId],
      answers,
      mediaFileId: newMediaFileId,
    }
    onSaveQuestion(newEntity)
  }

  const onChangeAnswers = (answers: string[]) => {
    setAnswers(answers)
    if (correctId !== null && correctId >= answers.length) {
      setCorrectId(answers.length - 1)
    }
  }

  const path = [
    {
      href: routes.questions,
      text: 'Questions',
    },
    {
      text: name,
    },
  ]

  const {
    mediaLoading,
    setMediaLoading,
    isOpen,
    handleOpen,
    handleClose,
    progressBarValue,
    onUploadProgress,
  } = useUploadAudioModal()

  const onSubmitAudio = async (newFile: File) => {
    setMediaLoading(true)
    let fileId: Nullish<number> = null
    try {
      const mediaResponse = await createMediaFile(newFile, onUploadProgress)
      fileId = mediaResponse.data.id
    } catch (e) {
    } finally {
      setMediaLoading(false)
    }

    return fileId
  }

  const onChangeAudio = async (newFile: File) => {
    setFile(newFile)
    handleClose()
  }

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: async () => {
        if (file) {
          setFile(null)
        }

        if (isDefined(mediaFileId)) {
          const entityResponse = await getByIdQuestion({ id: Number(data.id) })
          await updateQuestion({
            ...entityResponse.data,
            mediaFileId: null,
          })
        }
        setMediaFileId(null)
      },
      successCallback: async () => {
        NotificationSys.showSuccess('Audio successfully removed')
      },
      warning: "Can't remove audio file",
    })

  const handleDeleteAudio = () => {
    if (file && !isDefined(mediaFileId)) {
      setFile(null)
      return
    }

    if (isDefined(mediaFileId)) {
      openDeleteModal(mediaFileId)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          width="100%"
          noValidate
          spacing={3}
        >
          <Stack spacing={2} mb={-1}>
            <PageTitle>{pageTitle}</PageTitle>

            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Breadcrumbs path={path} />
                <Button variant="outlined" onClick={openEditQuestionName}>
                  Change Name
                </Button>
              </Stack>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ minWidth: 80 }}
                loading={saving || mediaLoading}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
          <QuestionEditorFieldStem value={stem} />
          <Stack spacing={1}>
            <Stack spacing={1} alignItems="flex-start">
              <Stack direction="row" spacing={1}>
                <FormLabel>Question</FormLabel>
                {isDefined(mediaFileId || file) && (
                  <AudioFileIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" size="small" onClick={handleOpen} sx={{ minWidth: 90 }}>
                  {isDefined(mediaFileId) || file ? 'Replace Audio' : 'Add Audio'}
                </Button>
                {(isDefined(mediaFileId) || file) && (
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={handleDeleteAudio}
                    sx={{ minWidth: 90 }}
                  >
                    Remove Audio
                  </Button>
                )}
              </Stack>
            </Stack>
            <QuestionEditorFieldQuestion value={question} maxLength={MAX_LENGTH.question} />
          </Stack>
          <QuestionEditorFieldInstruction value={instructions} maxLength={MAX_LENGTH.instruction} />
          <QuestionEditorFieldAnswersList
            answers={answers}
            correctId={correctId}
            onChangeAnswers={onChangeAnswers}
            onChangeCorrectId={setCorrectId}
            radio={type !== EQuestionType.query}
            maxLength={MAX_LENGTH.option}
          />
          {type !== EQuestionType.query && (
            <QuestionEditorFieldExplanation
              maxLength={MAX_LENGTH.explanation}
              value={explanation}
            />
          )}
        </Stack>
      </FormProvider>

      <ModalWithTextInput
        value={name}
        title="Change Question name"
        label="Question name"
        errorText="Question name is required"
        buttonText="Save"
        isOpen={isOpenEditQuestionName}
        handleClose={closeEditQuestionName}
        onSubmit={handleSubmitChangeName}
      />

      <UploadAudioModal
        isOpen={isOpen}
        onSubmit={onChangeAudio}
        handleClose={handleClose}
        value={mediaFileId}
        title="Upload Audio"
        loading={mediaLoading}
        progressBarValue={progressBarValue}
      />
      <ConfirmRemoveModal
        isOpen={isDefined(idToRemove)}
        entityToRemove="Question Audio"
        loading={removeLoading}
        handleClose={closeDeleteModal}
        handleConfirm={handleConfirmRemove}
      />
    </>
  )
}
