import styled from '@emotion/styled'
import { Link, Stack, Typography } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import React from 'react'
import { ArticleType, QuestionnaireResultData, QuestionnaireResultLessonToComplete } from 'types'
import ModalHeader from '../ModalHeader'

interface Props {
  handleClose: () => void
  retake: () => void
  name: string
  results?: QuestionnaireResultData
  lessonsToComplete?: QuestionnaireResultLessonToComplete[]
}

const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
`

const StyledLink = styled(Link)`
  cursor: pointer;
`

const FinalScreen = (props: Props) => {
  const { results, lessonsToComplete, handleClose } = props
  const { handleOpenViewArticle } = useDrawersContext()

  const handleClickLesson = (lessonId: number) => () => {
    handleOpenViewArticle(lessonId, null, ArticleType.Lesson)
    handleClose()
  }

  return (
    <Stack spacing={2}>
      <ModalHeader handleClose={handleClose}>
        <Typography fontWeight={400} variant="h5">
          Results panel
        </Typography>
      </ModalHeader>

      {!results && lessonsToComplete && lessonsToComplete.length > 0 && (
        <div>
          <Paragraph>
            You should review the following lessons so you can take the Exam to get a passing grade
            and a Certificate of Course Completion.
          </Paragraph>

          <Stack gap={1}>
            {lessonsToComplete.map((lessonItem) => {
              return (
                <StyledLink key={lessonItem.id} onClick={handleClickLesson(lessonItem.id)}>
                  {lessonItem.name}
                </StyledLink>
              )
            })}
          </Stack>
        </div>
      )}

      {results && results.status === 'passed' && (
        <div>
          <Paragraph>
            You have correctly answered {results.correctAnswerCount}/
            {results.answeredQuestions.length} questions. This is a passing grade.
          </Paragraph>
          <Paragraph>
            Your score for this Exam is recorded in your completed list in Progress and you have a
            Certificate of Course Completion in Thrive Together.
          </Paragraph>
        </div>
      )}
      {results && results.status === 'failed' && (
        <>
          <div>
            <Paragraph>
              You have correctly answered {results.correctAnswerCount}/
              {results.answeredQuestions.length} questions. This is not a passing grade.
            </Paragraph>
            <Paragraph>Your score for this Exam is recorded in Progress.</Paragraph>
            <Paragraph>
              If you review the following lessons you can retake the Exam to get a passing grade and
              a Certificate of Course Completion in Thrive Together.
            </Paragraph>
          </div>

          <Stack gap={1}>
            {results.lessonsToReview.map((lessonItem) => {
              if (lessonItem.lesson.deletedAt) {
                return <div key={lessonItem.lessonId}>{lessonItem.lesson.name}</div>
              }

              return (
                <StyledLink
                  key={lessonItem.lessonId}
                  onClick={handleClickLesson(lessonItem.lessonId)}
                >
                  {lessonItem.lesson.name}
                </StyledLink>
              )
            })}
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default FinalScreen
