import { Box, Button, Stack } from '@mui/material'
import {
  addCareArticleEngagementMetric,
  addLearnArticleEngagementMetric,
  addLessonEngagementMetric,
  addLessonViewMetric,
} from 'api/metrics'
import AudioView from 'components/audio/AudioView'
import { useAuthContext } from 'components/context/AuthProvider'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import React, { useCallback, useEffect, useMemo } from 'react'
import ArticlePreview from 'routes/admin/content-managment/lesson-content-edit/ArticlePreview'
import { useMetric } from 'services/Metric'
import { useActions } from 'store/hooks'
import {
  ArticleType,
  CareArticle,
  ETimelinePostStatus,
  IMetricScope,
  IMetricType,
  LearnArticle,
  Lesson,
} from 'types'
import { formatMetricDate } from 'utils'
import ArticleHeader from '../../ArticleHeader'

const isLesson = (obj: Lesson | LearnArticle | CareArticle): obj is Lesson => {
  return !!(obj as Lesson).courseId
}

const isLearnArticle = (obj: Lesson | LearnArticle | CareArticle): obj is LearnArticle => {
  return !!(obj as LearnArticle).topicId
}

const isCareArticle = (obj: Lesson | LearnArticle | CareArticle): obj is CareArticle => {
  return !isLesson(obj) && !isLearnArticle(obj)
}

export function ViewArticleDrawerContent({
  data,
  type,
  handleClose,
  onUpdateStatus,
}: {
  data: Lesson | LearnArticle | CareArticle
  type: ArticleType
  showStatusChange: boolean
  handleClose: (shouldClearUuid: boolean) => void
  onUpdateStatus: (status: ETimelinePostStatus) => void
}) {
  const onReviewLater = () => {
    onUpdateStatus(ETimelinePostStatus.review)
  }
  const onDone = () => {
    onUpdateStatus(ETimelinePostStatus.done)
  }

  const { user } = useAuthContext()
  const { getTimelineById } = useActions()

  const lesson = useMemo(() => {
    if (isLesson(data)) {
      return data
    }
  }, [data])

  const learnArticle = useMemo(() => {
    if (isLearnArticle(data)) {
      return data
    }
  }, [data])

  const careArticle = useMemo(() => {
    if (isCareArticle(data)) {
      return data
    }
  }, [data])

  const metricOptions = useMemo(() => {
    if (!!lesson) {
      return { type: IMetricType.lesson, scope: IMetricScope.lesson }
    }

    if (!!learnArticle) {
      return { type: IMetricType.learnArticle, scope: IMetricScope.learnArticle }
    }

    if (!!careArticle) {
      return { type: IMetricType.careArticle, scope: IMetricScope.careArticle }
    }

    return { type: undefined, scope: undefined }
  }, [careArticle, learnArticle, lesson])

  const handleSaveMetric = useCallback(
    async (value: number, uuid: string, scope: IMetricScope) => {
      if (!user) {
        return
      }

      if (type === ArticleType.Lesson) {
        await addLessonEngagementMetric({
          lessonUuid: uuid,
          lessonId: data.id,
          courseId: (data as Lesson).courseId,
          userId: user.id,
          engagedFrom: formatMetricDate(value),
          engagedTo: formatMetricDate(),
          scope,
        })
      } else if (type === ArticleType.LearnLibrary) {
        await addLearnArticleEngagementMetric({
          userId: user.id,
          learnArticleId: data.id,
          learnTopicId: (data as LearnArticle).topicId,
          scope,
          engagedFrom: formatMetricDate(value),
          engagedTo: formatMetricDate(),
        })
      } else if (type === ArticleType.CareArticle) {
        await addCareArticleEngagementMetric({
          userId: user.id,
          careArticleId: data.id,
          scope,
          engagedFrom: formatMetricDate(value),
          engagedTo: formatMetricDate(),
        })
      }
    },
    [data, type, user],
  )

  useMetric({
    type: metricOptions.type,
    scope: metricOptions.scope,
    entityId: data.id,
    hasIdleTimer: true,
    idleLimitMin: 2,
    onSave: handleSaveMetric,
  })

  useEffect(() => {
    if (!!lesson) {
      addLessonViewMetric({
        lessonId: lesson.id,
        courseId: (data as Lesson).courseId,
        userId: user?.id ?? 0,
        viewedAt: formatMetricDate(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleOpenQuestioning: handleOpenQuestionnaire, handleOpenViewArticle } =
    useDrawersContext()

  const onQuizTaken = useCallback(() => {
    getTimelineById({})
  }, [getTimelineById])

  const onClickTakeQuiz = useCallback(() => {
    if (lesson?.quizId) {
      handleOpenQuestionnaire({
        id: lesson?.quizId,
        mode: 'take',
        type: 'quiz',
        onQuestioningTaken: onQuizTaken,
      })
      handleClose(false)
    }
  }, [handleClose, handleOpenQuestionnaire, lesson, onQuizTaken])

  const onClickTakeQuery = useCallback(() => {
    if (learnArticle?.queryId) {
      handleOpenQuestionnaire({
        id: learnArticle.queryId,
        mode: 'take',
        type: 'query',
      })
      handleClose(false)
    }
  }, [handleClose, handleOpenQuestionnaire, learnArticle])

  const onClose = useCallback(() => {
    handleClose(true)
  }, [handleClose])

  const onClickReturnToLesson = useCallback(() => {
    if (!!learnArticle?.lessonId) {
      handleOpenViewArticle(learnArticle.lessonId, null, ArticleType.Lesson)
    }
  }, [handleOpenViewArticle, learnArticle])

  const onClickLearnMore = useCallback(() => {
    if (!!learnArticle?.careArticleId) {
      handleOpenViewArticle(learnArticle.careArticleId, null, ArticleType.CareArticle)
    } else if (!!careArticle?.learnArticleId) {
      handleOpenViewArticle(careArticle.learnArticleId, null, ArticleType.LearnLibrary)
    } else if (!!lesson?.learnArticleId) {
      handleOpenViewArticle(lesson.learnArticleId, null, ArticleType.LearnLibrary)
    }
  }, [learnArticle, careArticle, lesson, handleOpenViewArticle])

  return (
    <>
      <ArticleHeader
        type={type}
        date={data.createdAt}
        articleTitle={data.post || data.name}
        handleClose={onClose}
      />
      <AudioView
        mediaFileId={data.enMediaFileId}
        mediaFileMimeType={data.enMediaFile?.mimeType}
        metricType={metricOptions.type}
        metricEntityId={data.id}
        onSaveMetric={handleSaveMetric}
      />
      <Box mt={2} mb={2} fontSize={20} minHeight="128px">
        <ArticlePreview
          data={data.article}
          metricType={metricOptions.type}
          metricEntityId={data.id}
          onSaveVideoMetric={handleSaveMetric}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {isLesson(data) && !!data.quizId && (
            <Button variant="contained" color="secondary" onClick={onClickTakeQuiz}>
              Take quiz
            </Button>
          )}
          {!!learnArticle && !!learnArticle.queryId && (
            <Button variant="contained" color="secondary" onClick={onClickTakeQuery}>
              Take query
            </Button>
          )}
          {!!learnArticle && !!learnArticle.lessonId && (
            <Button variant="contained" color="secondary" onClick={onClickReturnToLesson}>
              Return to Lesson
            </Button>
          )}
          {!!learnArticle && !!learnArticle.careArticleId && (
            <Button variant="contained" color="secondary" onClick={onClickLearnMore}>
              Learn More
            </Button>
          )}
          {!!careArticle && !!careArticle.learnArticleId && (
            <Button variant="contained" color="secondary" onClick={onClickLearnMore}>
              Learn More
            </Button>
          )}
          {!!lesson && !!lesson.learnArticleId && (
            <Button variant="contained" color="secondary" onClick={onClickLearnMore}>
              Learn More
            </Button>
          )}
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button variant="contained" onClick={onReviewLater}>
            Review Later
          </Button>
          <Button variant="contained" onClick={onDone}>
            Done
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
