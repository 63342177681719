import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ArticleType, QuestioningType } from 'types'
import { ChangePasswordDrawer } from '../drawer/change-password/ChangePasswordDrawer'
import ProfileDrawer from '../drawer/profile/ProfileDrawer'
import { useShowControl } from '../../hooks/useShowControl'
import ViewNoteDrawer from '../drawer/note/ViewNoteDrawer'
import EditNoteDrawer from '../drawer/note/EditNoteDrawer'
import ViewArticleDrawer from '../drawer/article/view/ViewArticleDrawer'
import QuestionWizard from '../modals/questionare/question-wizard'
import PersonOfConcernProfileDrawer from '../drawer/concern-profile/PersonOfConcernProfileDrawer'
import SearchResultDrawer from '../drawer/search/SearchResultDrawer'
import InviteModal from '../modals/InviteModal'
import AboutUsDrawer from '../drawer/about-us/AboutUsDrawer'
import { noop } from '../../utils'
import AddMemberModal from '../modals/AddMemberModal'
import { useShowControlArticle } from './useShowControlArticle'
import TeamMemberProfileDrawer from '../drawer/TeamMemberProfileDrawer'

interface Props {
  children: React.ReactNode
}

const defaultValue = {
  handleOpenProfile: noop,
  handleOpenConcernProfile: noop,
  handleOpenNoteView: noop,
  handleOpenNoteEdit: noop,
  handleOpenViewArticle: noop,
  handleOpenQuestioning: noop,
  handleOpenSearchDrawer: noop,
  handleCloseSearchDrawer: noop,
  handleOpenInviteMember: noop,
  handleOpenAddMember: noop,
  handleOpenAboutUsDrawer: noop,
  handleOpenChangePasswordDrawer: noop,
  handleOpenTeamMemberDrawer: noop,
}

type QuestionerProps = {
  id: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraParameters?: any
  type: QuestioningType
  mode: 'take' | 'view_results'
  onQuestioningTaken?: () => void
}

type DrawersContextType = {
  handleOpenProfile: (id?: number) => void
  handleOpenConcernProfile: (id: number) => void
  handleOpenNoteView: (id: number, timelineId: number) => void
  handleOpenNoteEdit: (id?: number) => void
  handleOpenViewArticle: (id: number, timelineId: number | null, type: ArticleType) => void
  handleOpenQuestioning: (props?: QuestionerProps) => void
  handleOpenSearchDrawer: (str: string) => void
  handleCloseSearchDrawer: () => void
  handleOpenInviteMember: () => void
  handleOpenAddMember: (id?: number) => void
  handleOpenAboutUsDrawer: () => void
  handleOpenChangePasswordDrawer: () => void
  handleOpenTeamMemberDrawer: (id: number, isCanEdit: boolean) => void
}

const DrawersContext = React.createContext<DrawersContextType>(defaultValue)

export const useDrawersContext = () => {
  return useContext(DrawersContext)
}

const useQuestioningControl = (): [
  boolean,
  (props?: QuestionerProps) => void,
  () => void,
  QuestionerProps | undefined,
] => {
  const [props, setProps] = useState<QuestionerProps | undefined>()
  const [state, setState] = useState<boolean>(false)

  const onOpen = useCallback((props?: QuestionerProps) => {
    setState(true)
    setProps(props)
  }, [])

  const onClose = useCallback(() => {
    setState(false)
    setProps(undefined)
  }, [])

  return [state, onOpen, onClose, props]
}

const useShowIDState = (): [boolean, number | undefined, (id?: number) => void, () => void] => {
  const [isShow, setShow] = useState(false)
  const [id, setId] = useState<number>()

  const onOpen = (id?: number) => {
    setShow(true)
    setId(id)
  }

  const onClose = () => {
    setShow(false)
    setId(undefined)
  }

  return [isShow, id, onOpen, onClose]
}

const useShowControlNoteView = (): [
  number | null,
  number | null,
  (id: number, timelineId: number) => void,
  () => void,
] => {
  const [id, setId] = useState<number | null>(null)
  const [timelineId, setTimelineId] = useState<number | null>(null)

  const onOpen = (id: number, timelineId: number) => {
    setId(id)
    setTimelineId(timelineId)
  }

  const onClose = () => {
    setId(null)
    setTimelineId(null)
  }

  return [id, timelineId, onOpen, onClose]
}

const useShowControlSearch = (
  initial: boolean = false,
): [boolean, string, (str: string) => void, () => void] => {
  const [state, setState] = useState<boolean>(initial)
  const [search, setSearch] = useState('')

  const onOpen = useCallback((str: string) => {
    setSearch(str)
    setState(true)
  }, [])

  const onClose = useCallback(() => {
    setSearch('')
    setState(false)
  }, [])

  return [state, search, onOpen, onClose]
}

const useShowControlTeamMemberProfile = (initial: boolean = false) => {
  const [isOpen, setOpen] = useState<boolean>(initial)
  const [id, setId] = useState<number | null>(null)
  const [isCanEdit, setIsCanEdit] = useState(false)

  const onOpen = useCallback((id: number, isCanEdit: boolean) => {
    setOpen(true)
    setId(id)
    setIsCanEdit(isCanEdit)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
    setId(null)
    setIsCanEdit(false)
  }, [])

  return { isOpen, id, isCanEdit, onOpen, onClose }
}

const ClientsDrawersProvider = (props: Props) => {
  const [isOpenProfile, profileId, handleOpenProfile, handleCloseProfile] = useShowIDState()
  const [isOpenConcernProfile, carePersonId, handleOpenConcernProfile, handleCloseConcernProfile] =
    useShowIDState()
  const [idNoteView, noteTimelineIdView, handleOpenNoteView, handleCloseNoteView] =
    useShowControlNoteView()
  const [isOpenNoteEdit, idNoteEdit, handleOpenNoteEdit, handleCloseNoteEdit] = useShowIDState()
  const [
    articleDrawerOpen,
    showedArticleId,
    articleTimelineId,
    showedArticleType,
    handleOpenViewArticle,
    handleCloseViewArticle,
  ] = useShowControlArticle()

  const [_, handleOpenQuestioning, handleCloseQuestionning, questioningProps] =
    useQuestioningControl()
  const [isOpenInviteMember, handleOpenInviteMember, handleCloseInviteMember] = useShowControl()
  const [isOpenAddMember, memberId, handleOpenAddMember, handleCloseAddMember] = useShowIDState()

  const [isSearchDrawerOpen, searchDrawerText, handleOpenSearchDrawer, handleCloseSearchDrawer] =
    useShowControlSearch()
  const [isAboutUsDrawerOpen, handleOpenAboutUsDrawer, handleCloseAboutUsDrawer] = useShowControl()
  const teamMemberDrawer = useShowControlTeamMemberProfile()
  const [
    isChangePasswordDrawerOpen,
    handleOpenChangePasswordDrawer,
    handleCloseChangePasswordDrawer,
  ] = useShowControl()

  const value = useMemo(() => {
    return {
      handleOpenProfile,
      handleOpenConcernProfile,
      handleOpenNoteView,
      handleOpenNoteEdit,
      handleOpenViewArticle,
      handleOpenQuestioning,
      handleOpenSearchDrawer,
      handleCloseSearchDrawer,
      handleOpenInviteMember,
      handleOpenAddMember,
      handleOpenAboutUsDrawer,
      handleOpenChangePasswordDrawer,
      handleOpenTeamMemberDrawer: teamMemberDrawer.onOpen,
    }
  }, [
    handleOpenProfile,
    handleOpenConcernProfile,
    handleOpenNoteView,
    handleOpenNoteEdit,
    handleOpenViewArticle,
    handleOpenQuestioning,
    handleOpenSearchDrawer,
    handleCloseSearchDrawer,
    handleOpenInviteMember,
    handleOpenAddMember,
    handleOpenAboutUsDrawer,
    handleOpenChangePasswordDrawer,
    teamMemberDrawer.onOpen,
  ])

  return (
    <DrawersContext.Provider value={value}>
      {profileId && (
        <ProfileDrawer isOpen={isOpenProfile} handleClose={handleCloseProfile} userId={profileId} />
      )}
      {carePersonId && (
        <PersonOfConcernProfileDrawer
          isOpen={isOpenConcernProfile}
          handleClose={handleCloseConcernProfile}
          carePersonId={carePersonId}
        />
      )}
      <ViewNoteDrawer
        id={idNoteView}
        timelineId={noteTimelineIdView}
        handleClose={handleCloseNoteView}
      />
      <EditNoteDrawer
        isOpen={isOpenNoteEdit}
        id={idNoteEdit}
        handleClose={handleCloseNoteEdit}
        handleOpen={handleOpenNoteEdit}
      />
      {showedArticleId && (
        <ViewArticleDrawer
          id={showedArticleId}
          timelineId={articleTimelineId}
          type={showedArticleType}
          isOpen={articleDrawerOpen}
          handleClose={handleCloseViewArticle}
        />
      )}
      {!!questioningProps && (
        <QuestionWizard
          handleClose={handleCloseQuestionning}
          id={questioningProps.id}
          type={questioningProps.type}
          mode={questioningProps.mode}
          extraParams={questioningProps.extraParameters}
          onQuestioningTaken={questioningProps.onQuestioningTaken}
        />
      )}

      <InviteModal isOpen={isOpenInviteMember} handleClose={handleCloseInviteMember} />
      <AddMemberModal id={memberId} isOpen={isOpenAddMember} handleClose={handleCloseAddMember} />
      <SearchResultDrawer
        isOpen={isSearchDrawerOpen}
        searchQuery={searchDrawerText}
        handleClose={handleCloseSearchDrawer}
      />
      <AboutUsDrawer isOpen={isAboutUsDrawerOpen} handleClose={handleCloseAboutUsDrawer} />
      <TeamMemberProfileDrawer
        isOpen={teamMemberDrawer.isOpen}
        id={teamMemberDrawer.id}
        handleClose={teamMemberDrawer.onClose}
        isCanEdit={teamMemberDrawer.isCanEdit}
      />
      <ChangePasswordDrawer
        isOpen={isChangePasswordDrawerOpen}
        handleClose={handleCloseChangePasswordDrawer}
      />
      {props.children}
    </DrawersContext.Provider>
  )
}

export default ClientsDrawersProvider
